.footer-page{
  margin: 0 auto;
  background: url("/common/images/footer/bg/footer-bg.jpg");
  padding: 40px 0;
}

.footer-page .text-blue{
  color: #3598fe;
}

.footer-page p{
  text-align: center;
  font-size: 12px;
  color: #c3c1c5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-page .main-container{
  text-align: center;
}

.footer-page .main-container img {
  margin: 0 16px;
}

.footer-sub {
  margin: 0 auto;
  background: url("/common/images/footer/bg/bg-copyright.jpg");
  padding: 40px 0;
  text-align: center;
}

.footer-sub p {
  margin: 0;
}

.footer-sub span {
  color: #eaab33;
}
