/*coupon*/
.coupon-container{
  width: 1240px;
  height: 80px;
  background: url("/common/images/coupon/coupon-bg.jpg") center / 100% no-repeat;
  border-radius: 10px;
  margin: 10px 0;
  padding: 20px 0 0 335px;
}

.coupon-container form .form-control,
.coupon-container form .form-group,
.coupon-container form{
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.coupon-container form select.form-control {
  background: url(/common/images/chevron-down.png) center right 15px no-repeat #0c0c0e;
  width: 240px;
  border: 1px solid #72757e;
  border-radius: 4px;
}

.coupon-container form .form-control {
  border-radius: 4px;
  background: #0c0c0e;
  color: #ffffff;
  border: 1px solid #72757e;
  width: 240px;
  margin: 0 10px 0 0;
  box-shadow: none;
  height: 40px;
  font-size: 14px;
}

.coupon-container .btn-yellow {
  padding: 7px 10px;
  letter-spacing: -0.72px;
  font-weight: bold;
  border-radius: 4px;
  height: 40px;
  width: 60px;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
}

.coupon-container .btn-orange:hover,
.coupon-container .btn-yellow:hover {
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}

.coupon-container .btn-blue,
.coupon-container .btn-orange{
  width: 140px;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
}

.coupon-container .btn-blue{
  margin-left: 52px;
  margin-right: 4px;
  border: 1px solid #65d2ff;
  background: #06a4fe;
  background: -moz-linear-gradient(top,  #06a4fe 0%, #2760df 100%);
  background: -webkit-linear-gradient(top,  #06a4fe 0%,#2760df 100%);
  background: linear-gradient(to bottom,  #06a4fe 0%,#2760df 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06a4fe', endColorstr='#2760df',GradientType=0 );
}

.coupon-container .btn-orange:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}

.coupon-container .btn-orange,
.coupon-container .btn-blue:hover {
  border: 1px solid #fe301d;
  background: #fe301d;
  background: -moz-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: -webkit-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: linear-gradient(to bottom, #ea2c1a 0%, #7b1212 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
}
