.transaction-page{
  margin-bottom: 10px;
  overflow: hidden;
}
.transaction-page .transaction-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  background-color: #000000;
}

.transaction-table {
  width: calc(1008px / 3);
  border: 1px solid #424242;
  font-size: 13px;
  color: #999999;
}

.transaction-table:nth-of-type(1),
.transaction-table:nth-of-type(2) {
  border-right: 0;
}

.transaction-table:nth-of-type(3) {
  border-left: 1px solid #424242;
}


.transaction-page .transaction-table .table-heading {
  width: 100%;
  background: url("/common/images/customer/customer-header.jpg") top center / 100% #111111 no-repeat;
  font-size: 13px;
  color: #ffffff;
  padding: 7px 18px;
  font-weight: 600;
  letter-spacing: -0.8px;
  line-height: 49px;
}
.transaction-page .transaction-table:nth-child(2) .table-heading:active{
  color: #a77362;
}

.transaction-page .goldTxt {
  color: #f5aa18;
  font-weight: 600;
  text-align: center;
}

.transaction-page .text-white {
  color: #ffffff;
  font-weight: 600;
}

.transaction-page .text-white:hover {
  color: #f5aa18;
}

.transaction-page .transaction-table tr {
  height: 32px;
  text-align: center;
  background-color: #12131b;
}

.transaction-page .transaction-table tr:nth-of-type(odd) {
  background-color: #1c1d27;
}

.transaction-page .table-heading span.pull-right {
  color: #ffffff;
  font-size: 14px;
}

.transaction-page .table-heading span:hover.pull-right {
  color: #f5aa18;
  cursor: pointer;
}

.transaction-page .transaction-table{
  width: calc(100% / 3);
  overflow: hidden;
}

.transaction-page .table-heading span{
  font-size: 20px;
}


.transaction-page .table-heading.transaction-details .transaction-title:hover,
.transaction-page .table-heading.transaction-details .transaction-title.active,
.transaction-page .table-heading.transaction-details .transaction-title:focus{
  cursor: pointer;
  color: #f5aa18;
}

ul.transaction-list .items {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  padding: 11px 0;
  color: #999999;
  justify-content: space-between;
}

ul.transaction-list .transaction-item {
  width: calc(100% / 3);
}

ul.transaction-list{
  margin-bottom: 0;
  background: url("/common/images/customer/bg-event.png") bottom repeat-x;
  padding: 0 40px;
  height: 200px !important;
}
