.mp-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.mp-popup-container,
.mp-popup-container-popup-banner{
  margin: 10px;
  position: relative;
  width: 400px;
}
.mp-popup-container-popup-banner{
  width: 410px;
}
.ngdialog.ngdialog-theme-default.ngdialog-mulitple-popup{
  z-index: 10001;
}
.ngdialog.ngdialog-theme-default.ngdialog-mulitple-popup-banner{
  z-index: 10002;
}
.ngdialog-mulitple-popup.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 1291px;
  background-color: transparent;
}
.ngdialog-mulitple-popup-banner.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 1321px;
  background-color: transparent;
}

.mp-close-btn {
  font-size: 24px;
  padding: 4px 8px;
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 0;
  line-height: 1;
  transition: color 200ms ease-in-out;
  color: #ffffff;
}

.mp-close-btn:hover, .mp-close-btn:focus {

  color: #ff7f17;

}



.mp-not-today-btn-block {
  display: block;
  width: 100%;
}

.mp-not-today-btn {
  border: 1px solid #111110;
  background: #111110;
  background: -moz-linear-gradient(top, #111110 0%, #111110 100%);
  background: -webkit-linear-gradient(top, #111110 0%, #111110 100%);
  background: linear-gradient(to bottom, #111110 0%, #111110 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4181', endColorstr='#c61364', GradientType=0);
  color: #ffffff !important;
  display: block;
  border-radius: 0;
  height: 40px;
}

.mp-not-today-btn:hover ,.mp-not-today-btn:focus {
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17   0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17  0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4181', endColorstr='#c61364', GradientType=0);
  color: #ffffff;
}

.mp-wrapper .ngdialog-notice__content__footer{
  border: 1px solid #ff7f17;
  background: #111110;
  background: -moz-linear-gradient(top, #460c28 0%, #2b0918 100%);
  background: -webkit-linear-gradient(top, #460c28 0%,#2b0918 100%);
  background: linear-gradient(to bottom, #460c28 0%,#2b0918 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#460c28', endColorstr='#2b0918',GradientType=0 );
  display:grid;
  padding: inherit;

}
.mp-wrapper .ngdialog-notice__title{
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #460c28;
  background: -moz-linear-gradient(top, #460c28 0%, #2b0918 100%);
  background: -webkit-linear-gradient(top, #460c28 0%,#2b0918 100%);
  background: linear-gradient(to bottom, #460c28 0%,#2b0918 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#460c28', endColorstr='#2b0918',GradientType=0 );
}




