
.main-page .jackpot-container {
  /*width: 100%;*/
  margin-bottom: 10px;
  background-color: #000;
}

.main-page .jackpot-content {
  background: url("/common/images/main/coupon/coupon-bg.jpg") no-repeat;
  height: 100px;
  position: relative;
  border: 1px solid #424242;
}

.main-page .jackpot-content form{
  position: absolute;
  right: 10px;
  top: 28px;
}

.main-page .jackpot-content form .form-control,
.main-page .jackpot-content form .form-group {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.main-page .jackpot-content form .form-control{
  height: 40px;
}

.main-page .jackpot-content form select.form-control {
  background: url("/common/images/chevron-down-icon.svg") center right 15px no-repeat #181818;
  width: 240px;
  border: 1px solid #424242 ;
}

.main-page .jackpot-content form .form-control {
  border-radius: 0;
  background: #181818;
  border: 1px solid #424242 ;
  color: #999999;
  width: 240px;
  margin-right: 7px;
  box-shadow: none;
}

.main-page .jackpot-content form .btn-red{
  height: 34px;
}

.jackpot-container .btn-yellow {
  width: 60px;
  height: 40px;
}

.jackpot-container .btn-yellow,
.form-group.btn-coupon .btn-yellow:hover{
  background: #0d0c0c;
  color: #ffffff;
  border: 1px solid #f5aa18;
  transition: all .5s;
  font-size: 14px;
}

.form-group.btn-coupon .btn-yellow,
.jackpot-container .btn-yellow:hover{
  background: #f5aa18;
  border: 0;
  color: #ffffff;
  transition: all .5s;
}

.margin-right-55{
  margin-right: 17px;
}

.btn-coupon .btn-yellow{
  width: 140px;
  font-size: 14px;
}

.margin-right-10{
  margin-right: 10px;
}
