.ticker {
  margin: 0 auto;
  width: 80%;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.ticker ul {
  width: 100%;
  position: relative;
}

.ticker ul li {
  width: 100%;
  display: none;
}

