.ngdialog.ngdialog-theme-default.ngdialog-notice .ngdialog-content {
  width: 660px;
  border-radius: 0;
  padding: 0;
  color: #ffffff;
  font-size: 14px;
  background: #0b0b0b;
}

.ngdialog-notice-page .ngdialog-notice__title{
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #392812;
  background: -moz-linear-gradient(top, #392812 0%, #190e00 100%);
  background: -webkit-linear-gradient(top, #392812 0%,#190e00 100%);
  background: linear-gradient(to bottom, #392812 0%,#190e00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#392812', endColorstr='#190e00',GradientType=0 );
}

.ngdialog-notice-page hr{
  margin: 0;
  border-top: 1px solid #eaab33;
}

.ngdialog-notice-page .notice-body{
  padding: 10px 20px;
  border: 1px solid #eaab33;
}

.ngdialog-notice-page .ngdialog-notice__content{
  overflow-y: auto;
  height: 350px;
  padding: 0 10px;
}

.ngdialog-notice-page .ngdialog-notice__content__footer{
  padding: 10px;
  border: 1px solid #eaab33;
}

.ngdialog-notice-page .btn{
  border-radius: 0;
}

.ngdialog-notice-page .btn-yellow{
  border-radius: 4px;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
  padding: 10px;
  width: 160px;
  height: 40px;
  margin: 0 10px 0 0;
  transition: all .5s;
}

.ngdialog-notice-page .btn-yellow:hover{
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #FFFFFF;
}
