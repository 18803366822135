.wrapper{
  min-width: 1360px;
}

body{
  font-family: 'Noto Sans KR', sans-serif;
  color: #ffffff;
  overflow-y: scroll;
  /*font-size: 0;*/
}

.main-container{
  width: 1360px;
  margin: 0 auto;
  color: #cccccc;
  font-size: 13px;
  position: relative;
}

.bg-main{
  background: url("/common/images/main/bg-main.jpg") center top no-repeat #000000;
}
.bg-viewsport{
  background: #000000;
  overflow: hidden;
}

.list-inline {
  margin: 0;
}

.main-btn,
.main-btn{
  width: 160px;
  height: 43px;
  background-color: #182f0d;
  border: solid 1px #417e27;
  color: #fff;
  font-size: 15px;
  /*font-family: NotoSansKr-Bold;*/
  text-shadow: 0px 0px 20px #80b62a;
  transition: 0.5s;
  margin-top: 30px;
}

.main-btn:hover,
.main-btn:hover{
  background-color: #2b6211;
  border: solid 1px #a8e88b;
  color: #ffffff;
}

.close-btn{
  margin-top: 15px;
  font-weight: 600;
  width: 100px;
  height: 43px;
  background-color: #333333;
  border: solid 1px #454444;
  text-shadow: 0px 0px 5px #757879, 0px 0px 20px #757879;
  border-radius: 0;
  color: #ffffff;
}
.close-btn:hover{
  background-color: #191818;
  border: solid 1px #a5a4a4;
  color: #ffffff;
}

.textOverflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
}

.text-white{
  color: #ffffff;
}

a.text-white:hover,
a.text-white:focus{
  color: #62fd0e;
}

.btn-gray{
  background: #383838;
  border: 1px solid #454444;
  border-radius: 0;
  text-shadow: 0px 0px 5px #757879, 0px 0px 15px #757879, 0px 0px 20px #757879;
}

.btn-gray.clear{
  background: #101010;
}

.btn-gray:hover{
  background: #360d35;
  border: 1px solid #ac52a1;
  text-shadow: 0px 0px 20px #eb8aec, 0px 0px 15px #eb8aec;
  color: #ffffff;
}

button,
button.btn:focus,
button.btn:active:focus{
  outline: none;
  color: #ffffff;;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

select::-ms-expand {
  display: none;
}

select.form-control {
  color: #FFFFFF;
}

/*IE 11*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /*IE*/
  .slot-box {
    margin: 0 5px 10px 0 !important;
  }
  .loadingGifSlot{
    display: none !important;
  }
  .ngdialog-wallet-page .main-btn{
    width: auto !important;
    height: auto !important;
    margin-top: 0 !important;
    font-size: 11px !important;
    border-radius: 0 !important;
    border: none !important;
  }
  .ngdialog-wallet-page .won-text.deposit,
  .ngdialog-wallet-page .won-text.withdraw,
  .ngdialog-wallet-page .won-text.bonus{
    top: 5px !important;
  }
}


@supports (-ms-ime-align:auto) {/*edge*/
  .slot-box {
    margin: 0 5px 10px 0 !important;
  }
  .loadingGifSlot{
    display: none !important;
  }
}
/*Mozilla*/
@-moz-document url-prefix() {
  .slot-box{
    width: 179px !important;
  }
}

.disable-event {
  pointer-events: none;
  cursor: not-allowed;
}

.text-left{
  text-align: left !important;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  /*background-color: #b9b9b9 !important;*/
  /*opacity: 1;*/
}
 /*swal*/
.swal-title{
  font-size: 30px;
}
.swal-title,
.swal-text{
  text-align: center;
}

.swal-title{
  white-space: pre-wrap;
}

.swal-modal {
  width: 600px;
}
.ngdialog.ngdialog-theme-default.ngdialog-newpopup .ngdialog-content{
  min-width: 600px;
}

/*scrollbar*/
::-webkit-scrollbar-track
{
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*background-color: #181818;*/
}

::-webkit-scrollbar
{
  width: 10px;
  background-color: #505050;
}

::-webkit-scrollbar-thumb
{
  background: #ffbf00;
}
.csrf-swal .swal-title,
.csrf-swal .swal-text{
  font-size: 27px;
  font-weight: 600;
  color: rgba(0,0,0,.65);
}

textarea.form-control {
  resize: none;
  height: auto !important;
}

.badge {
  background: #cc001b;
}

.no-padding-r {
  padding-right: 0 !important;
}
.no-padding {
  padding: 0 !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
