.ngdialog.ngdialog-theme-default.ngdialog-signup .ngdialog-content{
  background: #0b0b0b;
  font-size: 14px;
  color: #999999;
  width: 800px;
  padding: 0;
  border-radius: 4px;
}

.ngdialog-signup-page .form-control{
  background: #080808;
  border: 1px solid #72757e;
  border-radius: 4px;
  margin-bottom: 15px;
  color: #999999;
  height: 40px;
}

.ngdialog-signup-page label{
  font-size: 14px;
  line-height: 40px;
  color: #ffffff;
}

.ngdialog-signup-page label span:nth-of-type(2){
  color: #cc001b;
}

.ngdialog-signup-page .form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ngdialog-signup-page .col-sm-4,
.ngdialog-signup-page .col-sm-5{
  padding: 0;
}

.ngdialog-signup-page .col-sm-4{
  line-height: 40px;
}

.ngdialog-signup-page .col-sm-4 p{
  background: #202020;
  width: 260px;
  position: absolute;
  color: #d54b4b;
  font-size: 14px;
}

.ngdialog-signup-page .col-sm-4 p>span:first-of-type,
.ngdialog-signup-page .col-sm-4 span.signup-inquiry{
  padding-left: 15px;
  white-space: pre;
}

.ngdialog-signup-page .col-sm-4 button{
  width: 94px;
  height: 40px;
  margin-top: -1px;
  border-radius: 4px;
  background: #cc001b;
  border: 1px solid #cc001b;
  transition: all .5s;
}

.ngdialog-signup-page .col-sm-4 button:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}

.ngdialog-signup-page .col-sm-4,
.ngdialog-signup-page .col-sm-5{
  padding: 0;
}

.ngdialog-signup-page .col-sm-3{
  width: 165px;
  text-align: right;
  padding-left: 0;
  padding-right: 15px;
}

.ngdialog-signup-page .ngdialog-signup__title{
  text-align: center;
  color: #ffffff;
}

.ngdialog-signup-page .ngdialog-signup__title>h1{
  letter-spacing: -1.6px;
  font-weight: bold;
  font-size: 35px;
  color: #c1ad8b;
  margin-bottom: 35px;
}

.ngdialog-signup-page .ngdialog-signup__title>p {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.84px;
}

.ngdialog-signup-page .logo{
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0;
  background: #392812;
  background: -moz-linear-gradient(top, #392812 0%, #1a0e01 100%);
  background: -webkit-linear-gradient(top, #392812 0%,#1a0e01 100%);
  background: linear-gradient(to bottom, #392812 0%,#1a0e01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#392812', endColorstr='#1a0e01',GradientType=0 );
}

.ngdialog-signup-page button.btn-red{
  border-radius: 4px;
}


.ngdialog.ngdialog-theme-default.ngdialog-signup .ngdialog-close:before {
  font-size: 35px;
  line-height: 26px;
  right: 20px;
  top: 20px;
  width: 30px;
  color: #ffffff;
}

.ngdialog.ngdialog-theme-default.ngdialog-signup .ngdialog-close:hover:before{
  color: #ff7f17;
}

.has-not-used.has-success .form-control{
  background: #080808 !important;
  border-color: #72757e !important;
  box-shadow: none !important;
}

.ngdialog-signup-page .has-success input.form-control, input.no-error {
  background: url(/common/images/icon-valid.png) right 10px center no-repeat #080808;
  border: 1px solid #53d14c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.ngdialog-signup-page .has-error .form-control {
  background: url(/common/images/icon-error.png) right 10px center no-repeat #080808;
  border: 1px solid #d54b4b !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.ngdialog-signup-page select.form-control{
  padding: 6px 12px;
}

.ngdialog-signup-page .won-text.arrow{
  position: absolute;
  top: 6px;
  right: 210px;
  margin: 0;
  font-size: 13px;
  color: #ffffff;
}

.ngdialog-signup-page select.form-control{
  background: url("/common/images/chevron-down.png") center right 15px no-repeat #080808;
  color: #FFFFFF;
}

.ngdialog-signup-page .btn-yellow{
  height: 45px;
}

.ngdialog-signup .form-signup{
  border: 1px solid #eaab33;
  padding: 30px;
}

.ngdialog-signup .btn-signup{
  background: url("/common/images/btn-frame-signup.jpg") center / 100% no-repeat;
}

.ngdialog-signup .btn-signup:hover{
  color: #040a1c;
}

.ngdialog-signup fieldset{
  width: 660px;
  margin: 0 auto;
}

.ngdialog-signup-page label span:first-child{
  padding-right: 1rem;
}

.ngdialog-signup-page .btn-yellow{
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  color: #ffffff;
  transition: all .5s;
}

.ngdialog-signup-page .btn-yellow:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #ffffff;
}

.signup-info {
  border: 1px solid #a77362;
  margin: 10px 0;
}

.signup-info ul {
  padding: 30px;
  margin-bottom: 0;
}

.signup-info ul li {
  list-style-type: none;
  font-size: 14px;
  font-weight: 900;
  color: #a77362;
  margin-bottom: 2px;
  margin-left: 20px;
}

.signup-info ul li:last-child {
  margin-left: 0;
  margin-top: 14px;
}

.signup-info ul li:before {
  content: "•";
  position: absolute;
  left: 3.9em;
  font-size: 1.2em;
}

.signup-info ul li:last-child:before {
  content: "";
  position: absolute;
  left: 2.4em;
  font-size: 1.2em;
}

.birthday-input {
  position: relative;
  background-color: white;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
}

.bdayDatePicker {
  left: 0 !important;
  top: 33px !important;
  position: absolute !important;
}

.field-gender {
  margin: 10px;
}

.gender-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field-gender .form-check {
  width: calc(100% / 2 - 5px);
  display: inline-table;
  margin-bottom: 10px;
}

.field-gender input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.field-gender .form-check-label {
  text-shadow: none;
  text-align: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
}

.field-gender .form-check-input[type="radio"]:hover,
.field-gender .form-check-input[type="radio"]:checked + label {

  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}
