  /* pagination */
.pagination-items {display: block;}
ul.pagination {background: none; border: none; border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; height: auto; margin: 10px 0; padding: 0; text-align: center;}
ul.pagination li {border: none; display: inline; float: none; height: 29px; line-height: 28px; padding: 0;}
ul.pagination li:first-child, ul.pagination li:last-child {border-radius: 0;-moz-border-radius: 0;-webkit-border-radius: 0;}
ul.pagination li:hover {background: none;}
ul.pagination li > a {background-color: #161616; border: 1px solid #505050; color: #999999; line-height: 29px; padding: 6px 12px; }
ul.pagination li > a:hover {background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%); color: #ffffff; border-color: #ff7f17;}
ul.pagination > .active > a, ul.pagination > .active > a:hover { background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%); color: #ffffff; border-color: #ff7f17;}
ul.pagination li.disabled > a {background-color: #161616; border-color: #505050; color: #999999; cursor: default;}

ul.pagination .pagination-prev,
ul.pagination .pagination-next{
  margin: -3px;
}
