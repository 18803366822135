.guest-page {
  font-size: 0;
}
.guest-page .content {
  position: relative;
  top: 30px;
  float: right;
  right: 20px;
}

.guest-page .form-control{
  width: 140px;
  height: 40px;
  display: inline-block;
  margin-right: 8px;
  background: #080808;
  border: solid 1px #72757e;
  vertical-align: middle;
  /* box-shadow: 0px 0px 6px #fff; */
  color: #ffffff;
}

.guest-page .btn-login{
  width: 105px;
  height: 40px;
  margin-right: 8px;
  vertical-align: middle;
  font-weight: bold;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #FFFFFF;
}

.guest-page .btn-login:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}

.guest-page .btn-signup{
  width: 105px;
  height: 40px;
  vertical-align: middle;
  font-weight: bold;
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #ffffff;
}

.guest-page .btn-signup:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}


/*user panel*/
.guest-page .user-panel{
  font-size: 13px;
  color: #ffffff;
}


.guest-page .user-panel * {
  margin-left: 5px;
 }

.guest-page .user-panel i {
  padding: 10px 12px;
  border: 1px solid #ffbf00;
  background: #f5aa18;
  border-radius: 50%;
  background: -moz-linear-gradient(top, #ffbf00 0%, #ce7400 100%);
  background: -webkit-linear-gradient(top, #ffbf00 0%, #ce7400 100%);
  background: linear-gradient(to bottom, #ffbf00 0%, #ce7400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf00', endColorstr='#ce7400', GradientType=0);
  border-radius: 50%;
}
.guest-page .user-panel img {
  height: 16px;
  margin: -3px 0 0 0;
}

.guest-page img.rank {
  height: 26px;
}

.guest-page .btn-comp{
  width: 60px;
  height: 40px;
  background: #0e9294;
  font-size: 13px;
  font-weight: bold;
  padding: 0;
}

.guest-page img{
  display: inline-block;
}

.guest-page .user-panel>img:nth-of-type(2),
.guest-page .user-panel>img:nth-of-type(4),
.guest-page .user-panel>img:nth-of-type(5){
  cursor: pointer;
}

.guest-page .btn-logout{
  width: 70px;
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  padding: 0;
  color: #000000;
  border: 1px solid #ffffff;
  background: #ffffff;
  background: -moz-linear-gradient(top,  #ffffff 0%, #d8d8d8 100%);
  background: -webkit-linear-gradient(top,  #ffffff 0%,#d8d8d8 100%);
  background: linear-gradient(to bottom,  #ffffff 0%,#d8d8d8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d8d8d8',GradientType=0 );

}

.guest-page .btn-wallet{
  width: 83px;
  height: 40px;
  border: 1px solid #fe301d;
  background: #fe301d;
  background: -moz-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: -webkit-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: linear-gradient(to bottom, #ea2c1a 0%, #7b1212 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  font-size: 13px;
  font-weight: bold;
  padding: 0;
}

.guest-page .btn-logout:hover,
.guest-page .btn-comp:hover,
.guest-page .btn-wallet:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}
