
.gsp-icon{
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 5px;
}

.gsp-logo-1012{background: url("../images/gsp-icons/icon-logo-ag.png")  center top / 100%  no-repeat;}/*Asiagaming*/
.gsp-logo-1005{background: url("../images/gsp-icons/icon-logo-micro.png")  center top / 100%  no-repeat;}/*Microgaming*/
.gsp-logo-1112{background: url("../images/gsp-icons/icon-logo-micro.png")  center top / 100%  no-repeat;}/*Microgaming*/
.gsp-logo-1036{background: url("../images/gsp-icons/icon-logo-micro.png")  center top / 100%  no-repeat;}/*Microgaming-teg*/
.gsp-logo-1088{background: url("../images/gsp-icons/icon-logo-micro.png")  center top / 100%  no-repeat;}/*Microgaming-Fg*/
.gsp-logo-1027{background: url("../images/gsp-icons/icon-logo-gp.png")  center top / 100%  no-repeat;}/*Gameplay*/
.gsp-logo-1004{background: url("../images/gsp-icons/icon-logo-gp.png")  center top / 100%  no-repeat;}/*Gameplay*/
.gsp-logo-1006{background: url("../images/gsp-icons/icon-logo-gp.png")  center top / 100%  no-repeat;}/*Gameplay*/
.gsp-logo-1030{background: url("../images/gsp-icons/icon-logo-m88.png")  center top / 100%  no-repeat;}/*M88*/
.gsp-logo-1023{background: url("../images/gsp-icons/icon-logo-og.png")  center top / 100%  no-repeat;}/*Oriental Gaming*/
.gsp-logo-1051{background: url("../images/gsp-icons/icon-logo-allbet.png")  center top / 100%  no-repeat;}/*Allbet*/
.gsp-logo-1040{background: url("../images/gsp-icons/icon-logo-taishan.png")  center top / 100%  no-repeat;}/*Taishan*/
.gsp-logo-1069{background: url("../images/gsp-icons/icon-logo-lotus.png")  center top / 100%  no-repeat;}/*Lotus*/
.gsp-logo-1052{background: url("../images/gsp-icons/icon-logo-bbin.png")  center top / 100%  no-repeat;}/*Bbin*/
.gsp-logo-1050{background: url("../images/gsp-icons/icon-logo-pornhub.png")  center top / 100%  no-repeat;}/*Pornhub*/
.gsp-logo-1047{background: url("../images/gsp-icons/icon-logo-playtech.png")  center top / 100%  no-repeat;}/*Playtech*/
.gsp-logo-1034{background: url("../images/gsp-icons/icon-logo-betsoft.png")  center top / 100%  no-repeat;}/*Betsoft*/
.gsp-logo-1053{background: url("../images/gsp-icons/icon-logo-netent.png")  center top / 100%  no-repeat;}/*NetEnt*/
.gsp-logo-1087{background: url("../images/gsp-icons/icon-logo-netent.png")  center top / 100%  no-repeat;}/*NetEnt FG*/
.gsp-logo-1037{background: url("../images/gsp-icons/icon-logo-habanero.png")  center top / 100%  no-repeat;}/*Habanero*/
.gsp-logo-1055{background: url("../images/gsp-icons/icon-logo-gg.png")  center top / 100%  no-repeat;}/*GG Fish*/
.gsp-logo-1018{background: url("../images/gsp-icons/icon-logo-cmd368.png")  center top / 100%  no-repeat;}/*CMD*/
.gsp-logo-1031{background: url("../images/gsp-icons/icon-logo-m88.png")  center top / 100%  no-repeat;}/*M88 Sports*/
.gsp-logo-1054{background: url("../images/gsp-icons/icon-logo-ibcbet.png")  center top / 100%  no-repeat;}/*IBC*/
.gsp-logo-1033{background: url("../images/gsp-icons/icon-logo-10bet.png")  center top / 100%  no-repeat;}/*10Bet*/
.gsp-logo-1044{background: url("../images/gsp-icons/icon-logo-10bet.png")  center top / 100%  no-repeat;}/*10Bet*/
.gsp-logo-1056{background: url("../images/gsp-icons/icon-logo-ug.png")  center top / 100%  no-repeat;}/*UG*/
.gsp-logo-1059{background: url("../images/gsp-icons/icon-logo-oddsking.png")  center top / 100%  no-repeat;}/*Oddsking*/
.gsp-logo-1060{background: url("../images/gsp-icons/icon-logo-pinnacle.png")  center top / 100%  no-repeat;}/*Pinnacle*/
.gsp-logo-1058{background: url("../images/gsp-icons/icon-logo-ultraplay.png")  center top / 100%  no-repeat;}/*Ultraplay*/
.gsp-logo-1048{background: url("../images/gsp-icons/icon-logo-goldenrace.png")  center top / 100%  no-repeat;}/*Golden Race*/
.gsp-logo-1061{background: url("../images/gsp-icons/icon-logo-playngo.png")  center top / 100%  no-repeat;}/*PlayNGo*/
.gsp-logo-1062{background: url("../images/gsp-icons/icon-logo-quickspin.png")  center top / 100%  no-repeat;}/*QuickSpin*/
.gsp-logo-1063{background: url("../images/gsp-icons/icon-logo-nyx.png")  center top / 100%  no-repeat;}/*Nyx*/
.gsp-logo-1064{background: url("../images/gsp-icons/icon-logo-bustabit.png")  center top / 100%  no-repeat;}/*Nyx*/
.gsp-logo-1065{background: url("../images/gsp-icons/icon-logo-pragmatic.png")  center top / 100%  no-repeat;}/*Pragmatic*/
.gsp-logo-1066{background: url("../images/gsp-icons/icon-logo-habanero.png")  center top / 100%  no-repeat;}/*Habanero - A*/
.gsp-logo-1067{background: url("../images/gsp-icons/icon-logo-hydako.png")  center top / 100%  no-repeat;}/*Hydako*/
.gsp-logo-1068{background: url("../images/gsp-icons/icon-logo-asc.png")  center top / 100%  no-repeat;}/*ACS*/
.gsp-logo-1072{background: url("../images/gsp-icons/icon-logo-evo.png")  center top / 100%  no-repeat;}/*Evolution*/
.gsp-logo-1070{background: url("../images/gsp-icons/icon-logo-idnpoker.png")  center top / 100%  no-repeat;}/*IDN Poker*/
.gsp-logo-1057{background: url("../images/gsp-icons/icon-logo-coilingdragon.png")  center top / 100%  no-repeat;}/*coiling dragon*/
.gsp-logo-10717{background: url("../images/gsp-icons/icon-logo-coilingdragon.png")  center top / 100%  no-repeat;}/*coiling dragon*/
.gsp-logo-1073{background: url("../images/gsp-icons/icon-logo-minigame.png")  center top / 100%  no-repeat;}/*Skycity Mini Game*/
.gsp-logo-1076{background: url("../images/gsp-icons/icon-logo-winningft.png")  center top / 100%  no-repeat;}/*Winning FT Sports*/
.gsp-logo-1078{background: url("../images/gsp-icons/icon-logo-muaythai.png")  center top / 100%  no-repeat;}/*Muay Thai*/
.gsp-logo-1079{background: url("../images/gsp-icons/icon-logo-rtg.png")  center top / 100%  no-repeat;}/*Winning FT Sports*/
.gsp-logo-1080{background: url("../images/gsp-icons/icon-logo-spade.png")  center top / 100%  no-repeat;}/*Spade Gaming*/
.gsp-logo-1049{background: url("../images/gsp-icons/icon-logo-beteast.png")  center top / 100%  no-repeat;}/*betEast*/
.gsp-logo-1081{background: url("../images/gsp-icons/icon-logo-ebet.png")  center top / 100%  no-repeat;}/*Ebet*/
.gsp-logo-1082{background: url("../images/gsp-icons/icon-logo-playson.png")  center top / 100%  no-repeat;}/*playson*/
.gsp-logo-1083{background: url("../images/gsp-icons/icon-logo-leander.png")  center top / 100%  no-repeat;}/*leander*/
.gsp-logo-1084{background: url("../images/gsp-icons/icon-logo-elk.png")  center top / 100%  no-repeat;}/*elk*/
.gsp-logo-1085{background: url("../images/gsp-icons/icon-logo-nolimit.png")  center top / 100%  no-repeat;}/*nolimit*/
.gsp-logo-1086{background: url("../images/gsp-icons/icon-logo-live22.png")  center top / 100%  no-repeat;}/*live22*/
.gsp-logo-1089{background: url("../images/gsp-icons/icon-logo-blueprint.png")  center top / 100%  no-repeat;}/*blueprint*/
.gsp-logo-1095{background: url("../images/gsp-icons/icon-logo-igk.png")  center top / 100%  no-repeat;}/*igk*/
.gsp-logo-1096{background: url("../images/gsp-icons/icon-logo-sbo.png")  center top / 100%  no-repeat;}/*sbo*/
.gsp-logo-1098{background: url("../images/gsp-icons/icon-logo-ttg.png")  center top / 100%  no-repeat;}/*sbo*/
.gsp-logo-1099{background: url("../images/gsp-icons/icon-logo-ameba.png")  center top / 100%  no-repeat;}/*sbo*/
.gsp-logo-1097{background: url("../images/gsp-icons/icon-logo-sag.png")  center top / 100%  no-repeat;}/*sbo*/
.gsp-logo-1008{background: url("../images/gsp-icons/icon-logo-dt.png")  center top / 100%  no-repeat;}/*sbo*/
.gsp-logo-1009{background: url("../images/gsp-icons/icon-logo-vivo.png")  center top / 100%  no-repeat;}/*sbo*/
.gsp-logo-1013{background: url("../images/gsp-icons/icon-logo-sexy.png")  center top / 100%  no-repeat;}/*sbo*/


.gsp-name{
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  font-weight: bold;
}

.game-type{
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.game-casino-1009{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1009{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1009{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1009{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1013{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1013{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1013{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1013{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1008{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1008{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1008{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1008{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1005{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1005{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1005{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1005{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1131{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1131{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1131{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1131{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1132{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1132{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1132{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1132{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1112{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1112{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1112{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1112{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1097{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1097{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1097{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1097{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1088{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1088{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1088{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1088{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1087{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1087{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1087{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1087{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1086{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1086{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1086{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1086{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1081{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1081{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1081{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1081{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1036{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1036{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1036{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1036{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1012{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1012{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1012{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1012{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1080{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1080{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1080{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1080{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1098{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1098{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1098{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1098{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1089{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1089{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1089{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1089{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1099{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1099{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1099{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1099{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1045{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1045{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1045{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1045{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1027{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1027{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1027{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1027{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1004{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1004{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1004{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1004{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1006{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1006{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1006{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1006{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1030{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1030{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1030{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1030{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1023{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1023{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1023{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1023{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1014{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1014{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1014{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1014{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1051{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1051{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1051{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1051{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1040{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1040{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1040{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1040{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1069{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1069{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1069{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1069{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1072{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1072{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1072{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1072{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1058{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1058{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1058{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1058{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1070{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1070{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1070{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1070{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1049{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1049{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1049{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1049{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1052{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1052{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1052{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1052{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1115{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1115{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1115{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1115{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1125{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1125{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1125{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1125{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1107{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1107{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1107{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1107{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1134{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1134{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1134{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1134{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1136{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1136{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1136{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1136{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1137{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1137{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1137{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1137{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1138{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1138{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1138{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1138{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1140{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1140{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1140{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1140{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1142{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1142{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1142{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1142{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1134{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1134{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1134{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1134{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1050{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1050{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1050{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1050{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1047{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1047{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1047{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1047{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1053{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1053{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1053{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1053{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1176{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1176{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1176{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1176{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1082{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1082{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1082{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1082{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1083{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1083{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1083{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1083{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1084{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1084{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1084{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1084{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1085{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1085{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1085{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1085{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1057{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1057{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1057{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1057{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1071{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1071{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1071{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1071{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1037{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1037{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1037{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1037{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1066{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1066{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1066{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1066{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1067{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1067{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1067{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1067{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1055{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1055{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1055{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1055{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1018{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1018{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1018{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1018{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1031{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1031{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1031{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1031{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1095{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1095{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1095{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1095{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1096{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1096{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1096{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1096{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1091{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1091{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1091{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1091{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1044{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1044{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1044{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1044{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1054{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1054{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1054{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1054{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1033{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1033{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1033{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1033{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1044{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1044{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1044{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1044{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1056{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1056{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1056{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1056{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1059{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1059{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1059{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1059{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1060{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1060{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1060{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1060{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1068{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1068{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1068{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1068{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1048{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1048{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1048{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1048{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1061{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1061{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1061{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1061{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1156{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1156{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1156{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1156{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1062{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1062{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1062{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1062{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1124{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1124{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1124{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1124{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1063{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1063{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1063{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1063{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1065{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1065{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1065{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1065{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1079{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1079{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1079{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1079{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1022{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1022{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1022{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1022{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1034{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1034{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1034{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1034{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1064{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1064{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1064{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1064{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1020{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1020{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1020{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1020{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1078{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1078{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1078{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1078{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1073{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1073{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1073{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1073{background: url("../images/game-icons-type/icon-other.png")  center top no-repeat; }

.game-casino-1076{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1076{background: url("../images/game-icons-type/icon-sports.png")  center top no-repeat; }
.game-slots-1076{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1076{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1118{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1118{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1118{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1118{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1110{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1110{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1110{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1110{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1111{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1111{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1111{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1111{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1100{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1100{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1100{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1100{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1103{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1103{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1103{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1103{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1104{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1104{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1104{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1104{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1105{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1105{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1105{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1105{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1151{background: url("../images/game-icons-type/icon-casino-off.png")  center top no-repeat;  }
.game-sports-1151{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1151{background: url("../images/game-icons-type/icon-slots.png")  center top no-repeat; }
.game-other-1151{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1153{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1153{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1153{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1153{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }

.game-casino-1147{background: url("../images/game-icons-type/icon-casino.png")  center top no-repeat;  }
.game-sports-1147{background: url("../images/game-icons-type/icon-sports-off.png")  center top no-repeat; }
.game-slots-1147{background: url("../images/game-icons-type/icon-slots-off.png")  center top no-repeat; }
.game-other-1147{background: url("../images/game-icons-type/icon-other-off.png")  center top no-repeat; }
