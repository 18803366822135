.ngdialog.ngdialog-theme-default.ngdialog-main-default .ngdialog-content {
  width: 900px;
  border-radius: 0;
  font-size: 14px;
  padding: 0;
}

.ngdialog__heading {
  background: #392812;
  background: -moz-linear-gradient(top, #392812 0%, #1a0e01 100%);
  background: -webkit-linear-gradient(top, #392812 0%,#1a0e01 100%);
  background: linear-gradient(to bottom, #392812 0%,#1a0e01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#392812', endColorstr='#1a0e01',GradientType=0 );
}

.ngdialog-settlement-page .ngdialog__heading {
  display: flex;
  height: 67px;
  justify-content: center;
  align-items: center;
}


.ngdialog__title {
  color: #ffffff;
  margin: 0;
  padding: 10px;
  text-transform: uppercase;
  font-size: 16px;
}

.ngdialog-main-nav{
  background-color: #0d0c0c;
  margin-bottom: 0;
  margin-left: 0;
}

.ngdialog-main-nav li{
  padding: 15px;
  float: left;
  color: #ffffff;
}

.ngdialog-main-nav li:hover,
.ngdialog-main-nav li:focus{
  color: #eaab33;
  cursor: pointer;
  border-bottom: 2px solid #eaab33;
}

.ngdialog-main-nav li.active{
  border-bottom: 2px solid #eaab33;
  color: #eaab33;
}

.ngdialog-main-default__content{
  padding: 20px;
  background-color: #0b0b0b;
  border: 1px solid #eaab33;
  border-top: 1px solid #34363d;
}

.ngdialog-main-nav .badge{
  background: #cc001b;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before{
  top: 20px;
  right: 20px;
  color: #fff;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before{
  color: #eaab33;
}

.ngdialog.ngdialog-theme-default.ngdialog-movie .ngdialog-content{
  border-radius: 0;
  padding: 0;
  width: 550px;
}
.ngdialog.ngdialog-theme-default.ngdialog-movie-2 .ngdialog-content{
  border-radius: 0;
  padding: 0;
  width: 400px;
}

.ngdialog.ngdialog-theme-default.ngdialog-movie{
  z-index: 10001;
  padding-top: 250px;
  padding-top: 250px;
  padding-top: 250px;
  padding-top: 250px;
  padding-top: 250px;
  padding-top: 250px;
  padding-top: 250px;
  padding-top: 250px;
}

.ngdialog-movie .ngdialog-overlay{
  background: rgba(0, 0, 0, 0.3);
}

.ngdialog.ngdialog-theme-default.ngdialog-movie-2{
  z-index: 10002;
  padding-top: 250px;
}

.ngdialog-movie-2 .ngdialog-overlay{
  background: rgba(0, 0, 0, 0.8);
}

.change-password .has-not-used.has-success .form-control{
  background: none #111111 !important;
  border-color: #585858 !important;
  box-shadow: none !important;
}

.change-password .has-success input.form-control, input.no-error {
  background: url(/common/images/icon-valid.png) right 10px center no-repeat #080808;
  border-color: #53d14c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.change-password .has-error .form-control {
  background: url(/common/images/icon-error.png) right 10px center no-repeat #080808;
  border-color: #d54b4b !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.change-password-error span {
  color: #a94442;
  font-weight: 700;
}

.notice-container {
  position: relative;
  background: #000;
  width: 100%;
  height: 40px;
}
