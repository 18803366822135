/*VIEW SPORTS*/

.sports-view-nav{
  background: #2a0918;
  border-bottom: 2px solid #141118;
  height: 50px;
  display: flex;
  align-items: center;
}

.sports-view-nav .sports-nav{
  margin-bottom: 0;
}

.sports-nav>li{
  display: inline-block;
  list-style: none;
  font-size: 14px;
  letter-spacing: -1.12px;
  text-transform: uppercase;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  margin: 0;
}

.sports-nav>li>a{
  padding-right: 40px;
  color: #ffffff;
}
.sports-nav>li>a:hover,
.sports-nav>li>a.active{
  color: #f3e5b1;
  text-decoration: none;
}

.sports-view-nav .user-balance-container{
  width: 220px;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
}

.sports-view-nav .user-balance-container .total-balance{
  border: 1px solid #585858;
  background: #302e36;
  padding: 0 8px;
  height: 31px;
  width: 189px;
  float: left;
  letter-spacing: 0;
}
.sports-view-nav .user-balance-container .total-balance:hover{
  border-color: #cab593;
  cursor: pointer;
}

.sports-view-nav .user-balance-container .show-wallet{
  float: left;
  width: 31px;
  height: 31px;
  background: #b2423f;
  font-size: 25px;
  text-align: center;
  padding-right: 3px;
}

.sports-view-nav .user-balance-container .show-wallet:hover{
  background: #c14d4a;
}

.sports-view-nav .user-balance-container .show-wallet i.fa-caret-right{
  padding-left: 5px;
}

iframe#sports1,
iframe#sports2,
iframe#sports3{
  height: calc(100vh - 160px);
}
