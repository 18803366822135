.quick-links-page{
  margin: 20px 0 auto;
  background: url(/common/images/bg-footer.jpg) center center no-repeat;
}
.quick-links-page .help-container{
  width: 420px;
  height: 372px;
  padding: 40px 0 40px 40px;
  float: left;
}

.quick-links-page .help-container p{
  color: #ffffff;
}

.quick-links-page .help-container p.qtitle{
  color: #999999;
}

.quick-links-page .help-container p,
.quick-links-page .quick-links-container p{
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #f5aa18;
}

.quick-links-page .help-container img{
  cursor: pointer;
}

.quick-links-page .credits-container{
  width: 420px;
  height: 416px;
  padding-top: 86px;
  float: left;
  text-align: center;
  font-size: 12px;
  color: #999999;
}

.quick-links-page .credits-container img{
  margin-bottom: 20px;
}

.quick-links-page .quick-links-container{
  width: 420px;
  height: 415px;
  float: left;
  padding: 40px;
  text-align: left;
  font-size: 14px;
  color: #ffffff;
}

.quick-links-page .quick-links-container .list-inline{
  margin-bottom: 15px;
  color: #999999;
}

.quick-links-page .quick-links-container .list-inline>li{
  position: relative;
  padding: 0 10px;
  line-height: 20px;
  cursor: pointer;
  font-weight: 600;
  transition: all .5s;
}

.quick-links-page .quick-links-container .list-inline>li:hover{
  color: #f5aa18;
}

.quick-links-page .quick-links-container .list-inline>li::after{
  content: "";
  width: 1px;
  height: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  background: #424242;
  transform: translateY(-50%);
}
.quick-links-page .quick-links-container .list-inline>li:last-child::after,
.quick-links-page .quick-links-container .list-inline.live>li:nth-child(3)::after,
.quick-links-page .quick-links-container .list-inline.live>li:nth-child(7)::after,
.quick-links-page .quick-links-container .list-inline.live>li:nth-child(10)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(3)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(7)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(11)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(15)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(23)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(27)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(30)::after,
.quick-links-page .quick-links-container .list-inline.slot>li:nth-child(19)::after{
  display: none;
}
.dm-image{
  position: relative;
}
.quick-links-page .help-container .dm-container{
  position: relative;
  display: inline;
}
.quick-links-page .dm-container .guest-dm{
  width: auto;
  background: #cc001b;
  text-align: center;
  height: 20px;
  vertical-align: top;
  color: #fff;
  right: -2px;
  top: -2px;
  position: absolute;
  font-size: 12px;
  border-radius: 50%;
}
.quick-links-page .help-container .quick-links-area{
  float: left;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  color: rgba(255,255,255,0.46);
}
.quick-links-page .quick-links-area .qtitle{
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  padding-top: 75px;
}
.quick-links-page .help-container .quick-links-area:hover .qtitle{
  color: #f5aa18;
}

.quick-links-page .quick-links-area .qtitle,
.quick-links-page .quick-links-area .quick-links-img{
  transition: all .5s;
}

.quick-links-page .quick-links-area .quick-links-img{
  width: 66px;
  height: 66px;
}

.quick-links-page .quick-links-area .quick-links-img.deposit{
  background: url("/common/images/quick-links/deposit-button.png") no-repeat;
}

.quick-links-page .quick-links-area .quick-links-img.withdraw{
  background: url("/common/images/quick-links/withdraw-button.png") no-repeat;
}

.quick-links-page .quick-links-area .quick-links-img.transfer{
  background: url("/common/images/quick-links/money-move-button.png") no-repeat;
}

.quick-links-page .quick-links-area .quick-links-img.dm{
  background: url("/common/images/quick-links/dm-button.png") no-repeat;
}

.quick-links-page .quick-links-area .quick-links-img.deposit:hover{
  background: url("/common/images/quick-links/deposit-button-hover.png") no-repeat;
}

.quick-links-page .quick-links-area .quick-links-img.withdraw:hover{
  background: url("/common/images/quick-links/withdraw-button-hover.png") no-repeat;
}

.quick-links-page .quick-links-area .quick-links-img.transfer:hover{
  background: url("/common/images/quick-links/money-move-button-hover.png") no-repeat;
}

.quick-links-page .quick-links-area .quick-links-img.dm:hover{
  background: url("/common/images/quick-links/dm-button-hover.png") no-repeat;
}
