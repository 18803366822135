.new-banner-container{
  height: 406px;
  width: 100%;
  background: url("/common/images/main/slider/main-banner.png") right bottom no-repeat;
  padding: 135px 0;
}

.new-banner-container .banner-container {
  width: 375px;
  text-align: center;
}
.new-banner-container .banner-container .btn {
  width: 195px;
  height: 40px;
  border-radius: 20px;
  position: relative;
  text-align: center;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
  box-shadow: 0 2px 2px 0 #080808a8;
  margin-bottom: 2px;
}
/*Slick*/
.new-banner-container .banner-container .slick-dots{
  bottom: -30px;
}
.new-banner-container .banner-container .slick-dots li{
  margin: 0 3px;
  width: 11px;
}
.new-banner-container .banner-container .slick-dots li button:before{
  opacity: .75;
  color: #0b0a0a;
  font-size: 7px;
}
.new-banner-container .banner-container .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #ff7f17;
  font-size: 7px;
}
.new-banner-container .banner-container .btn>img{
  position: absolute;
  top:-17px;
  left: 0;
}
.new-banner-container .banner-container .btn:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
}

.new-banner-container .banner-container .slick-slide:focus{
  outline: none;
}
