.jackpot-container{
  width: 1240px;
  height: 160px;
  background: url("/common/images/jackpot/jackpot.jpg") #000000 no-repeat;
  border-radius: 10px;
  transition: all .5s;
  cursor: pointer;
  position: relative;
  padding: 53px 30px 0;
}

.jackpot-container:hover{
  background: url("/common/images/jackpot/jackpot-hover.jpg") #000000 no-repeat;
}

.jackpot-container .jackpot-odometer{
  width: 582px;
  height: 92px;
  position: relative;
  overflow: hidden;
  float: right;
}

.jackpot-container .currency-sign{
  width: 105px;
  height: 92px;
  float: right;
}
