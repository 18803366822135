.customer-page {
  font-size: 13px;
  color: #999999;
  width: 100%;
  letter-spacing: -0.78px;
  background-color: #000000;
}

.customer-page .customer-content {
  border: 1px solid #424242;
}

.customer-page .customer-buttons {
  float: left;
  width: calc(100% / 4);
  height: 97px;
  position: relative;
  cursor: pointer;
  padding: 20px;
  background: #181818;
  text-align: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .customer-page .customer-buttons {
    padding: 18px;
  }
}

.customer-page .customer-buttons:nth-child(-n+2):hover {
  opacity: 0.7;
}

.customer-page .customer-buttons img {
  vertical-align: baseline;
}

.customer-page .customer-buttons:nth-of-type(-n+3)::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 50%;
  right: 0;
  top: 50%;
  background: #424242;
  transform: translateY(-50%);
}

.customer-page .text-con {
  display: inline-block;
  margin-left: 5px;
  text-align: left;
}

.customer-page .text-con p {
  margin: 0;
  color: #999999;
}
.customer-page p.goldTxt {
  letter-spacing: -1.44px;
  color: #f5aa18;
  font-size: 24px;
}
