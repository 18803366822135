/*MICRO POPUP*/
/*microgaming popup*/

.ngDialog-micro {
  background: url("/common/images/micro-popup/bg-micro-live.jpg") no-repeat;
  width: 900px;
  height: 500px;
  margin-top: 100px;
}



/*.ngdialog-microgaming.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  right: -60px;
  top: 20px;
  background: url("/common/images/close-white.png") no-repeat;
}*/

.ngDialog-micro .popup-content {
  padding: 78px 125px;
  border: unset;
}

.ngdialog-microgaming.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 900px;
  padding: 0;
}
.ngdialog-microgaming.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before, .ngdialog-microgaming.ngdialog.ngdialog-theme-default .ngdialog-close:active:before {
  background: unset;
}
.ngdialog-microgaming.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  background: unset;
}

.ngDialog-micro .popup-content div {
  display: inline-block;
}

.old-micro {
  background: url("/common/images/micro-popup/micro-live-button-old.jpg") no-repeat;
  width: 305px;
  height: 336px;
  margin-right: 35px;
}

.old-micro:hover {
  background: url("/common/images/micro-popup/micro-live-button-old-hover.jpg") no-repeat;
  cursor: pointer;
}

.new-micro {
  background: url("/common/images/micro-popup/micro-live-button-new.jpg") no-repeat;
  width: 305px;
  height: 336px;
}

.new-micro:hover {
  background: url("/common/images/micro-popup/micro-live-button-new-hover.jpg") no-repeat;
  cursor: pointer;
}

.btn.btn-old, .btn.btn-new {
  width: 200px;
  height: 32px;
  margin: 0 auto;
}

.btn.btn-old {
  background: #a5162a;
  border: 1px solid #e14158;
  color: #ffffff;
  font-weight: bold;
}

.btn.btn-new {
  background: #229e78;
  border: 1px solid #3ebe97;
  color: #ffffff;
  font-weight: bold;
}

.old-micro:hover .btn.btn-old, .new-micro:hover .btn.btn-new {
  opacity: .9;
}

.center-btn {
  text-align: center;
  width: 100%;
  margin-top: 275px;
  color: #ffffff;
}