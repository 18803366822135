/* language */
.language-translate {
  cursor: pointer;
  margin: 0;
  position: relative;
  display: inline-block;
}
.icon-lang {
  background: url("/common/images/lang-icons.png") no-repeat;
  display: inline-block;
  height: 24px;
  vertical-align: middle;
  width: 24px;
}

.language-list {
  background: #dadada;
  color: #333333;
  display: none;
  position: absolute;
  z-index: 999;
}

.language-list li {
  border-bottom: 1px dotted #c0c0c0;
  padding: 3px;
  width: 100px;
}

.language-list li:hover,
.language-list li:focus {
  background-color: #dfa030;
}

.language-aa {background-position: 0 0;}
.language-ab {background-position: -24px 0;}
.language-ae {background-position: -48px 0;}
.language-af {background-position: -72px 0;}
.language-ak {background-position: -96px 0;}
.language-am {background-position: -120px 0;}
.language-an {background-position: -144px 0;}
.language-ar {background-position: -168px 0;}
.language-as {background-position: -192px 0;}
.language-av {background-position: -216px 0;}
.language-ay {background-position: -240px 0;}
.language-az {background-position: -264px 0;}
.language-ba {background-position: -288px 0;}
.language-be {background-position: -312px 0;}
.language-bg {background-position: -336px 0;}
.language-bh {background-position: -360px 0;}
.language-bi {background-position: -384px 0;}
.language-bm {background-position: -408px 0;}
.language-bn {background-position: -432px 0;}
.language-bo {background-position: -456px 0;}
.language-br {background-position: -480px 0;}
.language-bs {background-position: -504px 0;}
.language-ca {background-position: -528px 0;}
.language-ce {background-position: -552px 0;}
.language-ch {background-position: -576px 0;}

.language-co {background-position: 0 -24px;}
.language-cr {background-position: -24px -24px;}
.language-cs {background-position: -48px -24px;}
.language-cu {background-position: -72px -24px;}
.language-cv {background-position: -96px -24px;}
.language-cy {background-position: -120px -24px;}
.language-da {background-position: -144px -24px;}
.language-de {background-position: -168px -24px;}
.language-dv {background-position: -192px -24px;}
.language-dz {background-position: -216px -24px;}
.language-ee {background-position: -240px -24px;}
.language-el {background-position: -264px -24px;}
.language-en {background-position: -288px -24px;}
.language-eo {background-position: -312px -24px;}
.language-es {background-position: -336px -24px;}
.language-et {background-position: -360px -24px;}
.language-eu {background-position: -384px -24px;}
.language-fa {background-position: -408px -24px;}
.language-ff {background-position: -432px -24px;}
.language-fi {background-position: -456px -24px;}
.language-fj {background-position: -480px -24px;}
.language-fo {background-position: -504px -24px;}
.language-fr {background-position: -528px -24px;}
.language-fy {background-position: -552px -24px;}
.language-ga {background-position: -576px -24px;}

.language-gd {background-position: 0 -48px;}
.language-gl {background-position: -24px -48px;}
.language-gn {background-position: -48px -48px;}
.language-gu {background-position: -72px -48px;}
.language-gv {background-position: -96px -48px;}
.language-ha {background-position: -120px -48px;}
.language-he {background-position: -144px -48px;}
.language-hi {background-position: -168px -48px;}
.language-ho {background-position: -192px -48px;}
.language-hr {background-position: -216px -48px;}
.language-ht {background-position: -240px -48px;}
.language-hu {background-position: -264px -48px;}
.language-hy {background-position: -288px -48px;}
.language-hz {background-position: -312px -48px;}
.language-ia {background-position: -336px -48px;}
.language-id {background-position: -360px -48px;}
.language-ie {background-position: -384px -48px;}
.language-ig {background-position: -408px -48px;}
.language-ii {background-position: -432px -48px;}
.language-ik {background-position: -456px -48px;}
.language-io {background-position: -480px -48px;}
.language-is {background-position: -504px -48px;}
.language-it {background-position: -528px -48px;}
.language-iu {background-position: -552px -48px;}
.language-ja {background-position: -576px -48px;}

.language-jv {background-position: 0 -72px;}
.language-ka {background-position: -24px -72px;}
.language-kg {background-position: -48px -72px;}
.language-ki {background-position: -72px -72px;}
.language-kj {background-position: -96px -72px;}
.language-kk {background-position: -120px -72px;}
.language-kl {background-position: -144px -72px;}
.language-km {background-position: -168px -72px;}
.language-kn {background-position: -192px -72px;}
.language-ko {background-position: -216px -72px;}
.language-kr {background-position: -240px -72px;}
.language-ks {background-position: -264px -72px;}
.language-ku {background-position: -288px -72px;}
.language-kv {background-position: -312px -72px;}
.language-kw {background-position: -336px -72px;}
.language-ky {background-position: -360px -72px;}
.language-la {background-position: -384px -72px;}
.language-lb {background-position: -408px -72px;}
.language-lg {background-position: -432px -72px;}
.language-li {background-position: -456px -72px;}
.language-ln {background-position: -480px -72px;}
.language-lo {background-position: -504px -72px;}
.language-lt {background-position: -528px -72px;}
.language-lu {background-position: -552px -72px;}
.language-lv {background-position: -576px -72px;}

.language-mg {background-position: 0 -96px;}
.language-mh {background-position: -24px -96px;}
.language-mi {background-position: -48px -96px;}
.language-mk {background-position: -72px -96px;}
.language-ml {background-position: -96px -96px;}
.language-mn {background-position: -120px -96px;}
.language-mr {background-position: -144px -96px;}
.language-ms {background-position: -168px -96px;}
.language-mt {background-position: -192px -96px;}
.language-mm {background-position: -216px -96px;}
.language-na {background-position: -240px -96px;}
.language-nb {background-position: -264px -96px;}
.language-nd {background-position: -288px -96px;}
.language-ne {background-position: -312px -96px;}
.language-ng {background-position: -336px -96px;}
.language-nl {background-position: -360px -96px;}
.language-nn {background-position: -384px -96px;}
.language-no {background-position: -408px -96px;}
.language-nr {background-position: -432px -96px;}
.language-nv {background-position: -456px -96px;}
.language-ny {background-position: -480px -96px;}
.language-oc {background-position: -504px -96px;}
.language-oj {background-position: -528px -96px;}
.language-om {background-position: -552px -96px;}
.language-or {background-position: -576px -96px;}

.language-os {background-position: 0 -120px;}
.language-pa {background-position: -24px -120px;}
.language-pi {background-position: -48px -120px;}
.language-pl {background-position: -72px -120px;}
.language-ps {background-position: -96px -120px;}
.language-pt {background-position: -120px -120px;}
.language-qu {background-position: -144px -120px;}
.language-rm {background-position: -168px -120px;}
.language-rn {background-position: -192px -120px;}
.language-ro {background-position: -216px -120px;}
.language-ru {background-position: -240px -120px;}
.language-rw {background-position: -264px -120px;}
.language-sa {background-position: -288px -120px;}
.language-sc {background-position: -312px -120px;}
.language-sd {background-position: -336px -120px;}
.language-se {background-position: -360px -120px;}
.language-sg {background-position: -384px -120px;}
.language-si {background-position: -408px -120px;}
.language-sk {background-position: -432px -120px;}
.language-sl {background-position: -456px -120px;}
.language-sm {background-position: -480px -120px;}
.language-sn {background-position: -504px -120px;}
.language-so {background-position: -528px -120px;}
.language-sq {background-position: -552px -120px;}
.language-sr {background-position: -576px -120px;}

.language-ss {background-position: 0 -144px;}
.language-st {background-position: -24px -144px;}
.language-su {background-position: -48px -144px;}
.language-sv {background-position: -72px -144px;}
.language-sw {background-position: -96px -144px;}
.language-ta {background-position: -120px -144px;}
.language-te {background-position: -144px -144px;}
.language-tg {background-position: -168px -144px;}
.language-th {background-position: -192px -144px;}
.language-ti {background-position: -216px -144px;}
.language-tk {background-position: -240px -144px;}
.language-tl {background-position: -264px -144px;}
.language-tn {background-position: -288px -144px;}
.language-to {background-position: -312px -144px;}
.language-tr {background-position: -336px -144px;}
.language-ts {background-position: -360px -144px;}
.language-tt {background-position: -384px -144px;}
.language-tw {background-position: -408px -144px;}
.language-ty {background-position: -432px -144px;}
.language-ug {background-position: -456px -144px;}
.language-uk {background-position: -480px -144px;}
.language-ur {background-position: -504px -144px;}
.language-uz {background-position: -528px -144px;}
.language-ve {background-position: -552px -144px;}
.language-vi {background-position: -576px -144px;}

.language-vo {background-position: 0 -168px;}
.language-wa {background-position: -24px -168px;}
.language-wo {background-position: -48px -168px;}
.language-xh {background-position: -72px -168px;}
.language-yi {background-position: -96px -168px;}
.language-yo {background-position: -120px -168px;}
.language-za {background-position: -144px -168px;}
.language-zh {background-position: -168px -168px;}
.language-zu {background-position: -192px -168px;}
