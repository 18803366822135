.ngdialog-customer-page a{
  color: #999999;
  font-weight: bold;
}


.ngdialog-customer-page .history-table.table tr>td {
  color: #999999;
}

.ngdialog-customer-page a:hover{
  color: #eaab33;
  text-decoration: none;
}

.ngdialog.ngdialog-theme-default.ngdialog-main-default.ngdialog-customer .ngdialog-content{
  background: #131313;
  color: #fff;
  border-radius: 10px;
}

.ngdialog-customer-page .table>thead>tr>th,
.ngdialog-customer-page .table>tbody>tr>th,
.ngdialog-customer-page .table>tfoot>tr>th,
.ngdialog-customer-page .table>thead>tr>td,
.ngdialog-customer-page .table>tbody>tr>td,
.ngdialog-customer-page .table>tfoot>tr>td {
  border-top: 1px solid #505050;
}

.ngdialog-customer-page .panel{
  background: #000000;
  color: #fff;
}

.ngdialog-customer-page .panel-body{
  background: #161616;
  color: #fff;
  border-radius: 0 0 4px 4px;
  word-break: break-all;
  overflow-x: auto;
}

.ngdialog-customer-page .panel-body img {
  width: 100%;
}

.ngdialog-customer-page .panel-primary>.panel-heading {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.ngdialog-customer-page .panel-primary {
  border-color: #505050;
}

.width6{
  width: 60px;
}

.width13{
  width: 130px;
}

.ngdialog-customer__header {
  color: #999999;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #392812;
  background: -moz-linear-gradient(top, #392812 0%, #1a0e01 100%);
  background: -webkit-linear-gradient(top, #392812 0%,#1a0e01 100%);
  background: linear-gradient(to bottom, #392812 0%,#1a0e01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#392812', endColorstr='#1a0e01',GradientType=0 );
}

.ngdialog-customer-page .ngdialog-main-nav {
  background: #080808;
  border: 1px solid #eaab33;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.ngdialog.ngdialog-theme-default .ngdialog-customer-page .ngdialog-close:before{
  top: 20px;
  right: 10px;
}

.badge.badge-dm{
  background-color: #b73528;
}

.ngdialog-customer-page .form-control{
  background: #000000;
  border: 1px solid #424242;
  color: #fff;
}

.ngdialog-customer-page textarea.form-control{
  background: #000000;
  border: 1px solid #424242;
  color: #fff;
}

.ngdialog-customer-page .btn-process.btn-block{
  width: 436px;
  margin: 0 auto;
}

.back-button button{
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
}

.back-button button:hover{
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #FFFFFF;
}


.width28{
  width: 280px;
}

.width20{
  width: 200px;
}

.dm__title{
  text-align: left !important;
  padding-left: 20px !important;
}

.dm-body span br {
  display: none;
}

.ngdialog-customer-page .btn-violet.btn-block {
  width: 436px;
  margin: 0 auto;
  line-height: 30px;
  border-radius: 0;
}

.read.panel.panel-primary {
  margin-left: 30px;
}

.width12{
  width: 100px;
}

.width70{
  width: 630px;
}

.customerDm .width70 {
  width: 365px;
}

.ngdialog-customer-page .table>tbody>tr>th{
  border-top: 0;
}

.ngdialog-customer-page .btn-violet{
  height: 45px;
  border: 1px solid #460c28;
  background: #460c28;
  background: -moz-linear-gradient(top,  #460c28 0%, #2b0918 100%);
  background: -webkit-linear-gradient(top,  #460c28 0%,#2b0918 100%);
  background: linear-gradient(to bottom,  #460c28 0%,#2b0918 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#460c28', endColorstr='#2b0918',GradientType=0 );
  color: #fff;
}
.ngdialog-customer-page .btn-violet:hover{
  border: 1px solid #ffbf00;
  background: #000000;
  color: #ffffff;
}

.ngdialog-customer-page form > fieldset {
  margin-bottom: 20px;
}
