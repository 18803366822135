.navigation-page{
  display: flex;
  background: url("/common/images/main/bg-header.jpg") top center repeat-x #000000;
  width: 100%;
  height: 100px;
  position: relative;
  font-size: 0;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.8);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.8);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.8);
}


.navigation-page .logo{
  position: relative;
  float: left;
  height: 100%;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.navigation-page .logo.sportsView{
  height: 130px;
}

.navigation-page .logo img{
/*  position: absolute;
  left:55px;
  top: 35px;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 2;*/
}


.navigation-page .logo .bg {
  animation: logo_bg_anim 8s ease infinite;
  top: -35px;
}

.navigation-page .logo .super {
  animation: logo_super_anim 8s ease infinite;
  left: -15px;
  margin-top: 55px;
}

.navigation-page .logo .six {
  animation: logo_six_anim 8s ease infinite;
  margin-top: 20px;
}

.navigation-page .logo .casino {
  animation: logo_casino_anim 8s ease infinite;
  left: 12px;
}

.navigation-page nav{
  position: relative;
  left: 30px;
  top: 40px;
  float: left;
}

.navigation-page nav .nav-list{
  font-size: 0;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  /*text-shadow: 2PX 1px 15PX #ffffff;*/
}

.navigation-page nav .list-inline>li {
  padding: 0;
}

.navigation-page nav .list-inline>li a {
  padding: 0;
  color: #ffffff;
  font-size: 14px;
  padding-right: 40px;
  text-decoration: none;
  cursor: pointer;
}

.navigation-page nav .list-inline>li:hover a{
  color: #eaab33;
  /*text-shadow: 2PX 1px 15PX #ffd700;*/
}


/*animation*/

@keyframes logo_bg_anim {
  0% {
    transform: rotate(-5060deg) scale(0);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  100% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes logo_super_anim {
  0% {
    transform: scale(0);
    opacity: 0;
    top: 0;
  }

  20% {
    transform: scale(0);
    opacity: 0;
    top: 0;
  }

  30% {
    transform: scale(1);
    opacity: 1;
  }

  40% {
    transform: scale(1);
    opacity: 1;
    top: -51px;
  }

  100% {
    transform: scale(1);
    opacity: 1;
    top: -51px;
  }
}

@keyframes logo_six_anim {
  0% {
    transform: scale(0);
    opacity: 0;
    top: 0;
  }

  25% {
    transform: scale(0);
    opacity: 0;
    top: 15px;
  }

  35% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
    top: 25px;
  }
}

@keyframes logo_casino_anim {
  0% {
    transform: scale(0);
    opacity: 0;
    top: 0;
  }

  30% {
    transform: scale(0);
    opacity: 0;
    top: 0;
  }

  40% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: 1;
    top: 85px;
  }

  100% {
    transform: scale(1);
    opacity: 1;
    top: 85px;
  }
}
