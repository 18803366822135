.ngdialog .ngdialog-theme-default .ngdialog-main-default
.transfer-block{
  padding-right: 5px;
}

.transfer form{
  margin-top: 10px;
  padding: 20px;
  background-color: #0b0b0b;
  border: 1px solid #505050;
}

.balance-overflow{
  height: 600px;
  overflow-y: scroll;
  margin-top: 10px;
}

.balance__content:nth-child(even) {
  background-color: #161616;
}

.balance__content:nth-child(odd) {
  background-color: #0b0b0b;
}

.balance__content {
  border-bottom: 1px solid #505050;
  padding: 7px 10px;
}

.balance__content:last-of-type {
  padding: 10px;
}

.ngdialog-wallet-page .transfer fieldset {
  padding: 10px 0;
}

.ngdialog-wallet-page .transfer fieldset:first-child {
  padding-top: 0;
}

.ngdialog-wallet-page fieldset{
  padding: 20px 0;
}

.ngdialog-wallet-page fieldset:first-child{
  padding-top: 0;
}

.has-success .signup-error {
  display: none;
}

/*.ngdialog-wallet-page fieldset:nth-child(odd){
 background-color: #101010;
  border-top: 1px solid #333333;
}
.ngdialog-wallet-page fieldset:nth-child(even){
  background-color: #1e1e1e;
  border-top: 1px solid #333333;
}*/

/*.ngdialog-wallet-page form fieldset:last-of-type{*/
/*  margin-bottom: 10px;*/
/*  padding-bottom: 0;*/
/*  border-bottom: none;*/
/*}*/

.deposit label,
.withdraw label{
  float: left;
  line-height: 40px;
  width: 120px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 0;
}

.deposit label span + span,
.withdraw label span + span{
  color: #991528;
}

.side-note{
  color: #999;
  line-height: 40px;
}

.history-table > tbody > tr > th {
  background-color: #505050;
  color: #ffffff;
}
.history-table > tbody > tr > th,
.history-table > tbody > tr > td {
  padding: 10px;
  height: 40px;
  text-align: center;
}

.history-table tr:nth-of-type(even) td{
  background: #11151f;
}

.ngdialog-wallet-page .bonus-history form fieldset{
  padding: 10px;
  margin-bottom: 0;
  background-color: #161616;
}

.ngdialog-wallet-page .transfer .won-text{
  position: absolute;
  top: 46px;
  right: 10px;
  font-size: 13px;
  margin-bottom: 10px;
  color: #ffffff;
}

.ngdialog-wallet-page .deposit .won-text,
.ngdialog-wallet-page .withdraw .won-text{
  position: absolute;
  top: 10px;
  right: 30px;
  margin: 0;
  font-size: 14px;
  color: #fff;
}

.ngdialog-wallet-page .form-control.wonText{
  padding-right: 25px;
  color: #ffffff;
  background: #080808;
}

.ngdialog-wallet-page .won-text.deposit,
.ngdialog-wallet-page .won-text.withdraw,
.ngdialog-wallet-page .won-text.bonus{
  position: absolute;
  top: 10px;
  right: 25px;
  margin: 0;
  color: #fff;
}

.ngdialog.ngdialog-theme-default.ngdialog-main-default.ngdialog-wallet .ngdialog-content {
  border-radius: 0;
  background: #131313;
  color: #ffffff;
  width: 1024px;
}

.ngdialog-wallet-page .reload_balance{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  color: #000000;
  width: 40px;
  font-size: 14px;
  border-radius: 0;
}
.ngdialog-wallet-page .reload_balance:hover{
  border: 1px solid #ff7f17;
  background: #000000;
  color: #ffffff;
}

.ngdialog-wallet-page .form-control{
  background: #080808;
  border: 1px solid #72757e;
  border-radius: 4px;
  color: #999999;
  height: 40px;
}

.ngdialog-wallet-page select.form-control{
  background: #080808;
  border: 1px solid #72757e;
  border-radius: 4px;
  color: #ffffff;
  height: 40px;
}

.ngdialog-wallet-page .table>thead>tr>th,
.ngdialog-wallet-page .table>tbody>tr>th,
.ngdialog-wallet-page .table>tfoot>tr>th,
.ngdialog-wallet-page .table>thead>tr>td,
.ngdialog-wallet-page .table>tbody>tr>td,
.ngdialog-wallet-page .table>tfoot>tr>td {
  vertical-align: middle;
  border-top: 0;
}

.ngdialog-wallet-page hr{
  border-top: 1px solid #505050;
}

.ngdialog-wallet-page .alert-danger{
  background: #080808;
  color: #eaab33;
  border: 1px solid #505050;
  padding: 20px;
}

.ngdialog-wallet-page .transfer.alert-danger,
.ngdialog-wallet-page .deposit.alert-danger{
  background: #080808;
  color: #eaab33;
  border: 1px solid #505050;
}

.ngdialog-wallet-page .transfer.alert-danger p{
  font-weight: 700;
}
.ngdialog-wallet-page label>i:first-of-type {
  color: #eaab33;
  font-size: 14px;
  position: relative;
  top: 0px;
  text-shadow: none;
  margin-right: 5px;
}

.ngdialog-wallet-page .main-btn{
  width: unset;
  height: unset;
  margin-top: unset;
  font-size: unset;
  border-radius: 0;
  border: none;
}
/*Settlement Popup*/
.withdraw.manual .alert-danger p strong{
  font-size: 14px;
}
.withdraw-title{
  height: 72px;
  background: #1c1c1c;
}

.withdraw-title h4{
  margin: 0;
  line-height: 72px;
}

.ngdialog.ngdialog-theme-default.ngdialog-settlement .ngdialog-content {
  border-radius: 0;
  border-image-repeat: repeat;
  background: #0b0b0b;
  color: #ffffff;
  width: 640px;
  padding: 0;
}

.col-xs-4.bank{
  padding-right: 0;
}

input.placeholder-center::placeholder{
  text-align: center;
}

.btn-process{
  background: #5027aa;
  background: -moz-linear-gradient(top, #5027aa 0%, #291d5d 100%);
  background: -webkit-linear-gradient(top, #5027aa 0%,#291d5d 100%);
  background: linear-gradient(to bottom, #5027aa 0%,#291d5d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5027aa', endColorstr='#291d5d',GradientType=0 );
  border: 1px solid #683ac9;
  color: #ffc527;
  line-height: 30px;
}

.btn-process:hover,
.btn-process:focus,
.btn-process:active{
  border: 1px solid #fad981;
  background: -moz-linear-gradient(top, #efd48c 0%, #ddad2d 100%);
  background: -webkit-linear-gradient(top, #efd48c 0%,#ddad2d 100%);
  background: linear-gradient(to bottom, #efd48c 0%,#ddad2d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efd48c', endColorstr='#ddad2d',GradientType=0 );
  color: #fff;
}

.walletCat{
  background: #505050;
  padding: 11px 15px;
  color: #fff;
}

.form-control[disabled]{
  background: #080808;
  border: 1px solid #72757e;
}

textarea.form-control {
  background: #000;
}

.wallet-button{
  background: #cc001b ;
  border-color: #cc001b ;
  width: unset;
  margin-top: unset;
  border-radius: 0;
  padding: 0 8px;
  color: #fff;
  height: 40px;
  font-size: 14px;
}

.btn-wallet-amount {
  background: #cc001b;
  border: 1px solid #cc001b;
  color: #ffffff;
  border-radius: 0;
  flex: auto;
  height: 34px;
  transition: all .5s;
}

.btn-wallet-clear {
  background: #444444;
  border-color: #444444;
  color: #ffffff;
  border-radius: 0;
}

.wallet-button:hover,
.wallet-button:focus,
.wallet-button:active{
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #ffffff;
}

.deposit .btn-process.btn-block,
.withdraw .btn-process.btn-block,
.change-password .btn-process.btn-block{
  width: 436px;
  margin: 0 auto;
}

.deposit fieldset, .withdraw fieldset {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.ngdialog-wallet-page .table>tbody>tr>td{
  padding: 10px;
  font-weight: normal;
  color: #999999;
  border-top: 1px solid #505050;
  text-align: center;
  vertical-align: middle;
}

.btn-process.btn-point{
  width: calc(100% / 2 - 2px);
}

.btn-process.btn-point.active{
  border: 1px solid #fad981;
  background: -moz-linear-gradient(top, #efd48c 0%, #ddad2d 100%);
  background: -webkit-linear-gradient(top, #efd48c 0%,#ddad2d 100%);
  background: linear-gradient(to bottom, #efd48c 0%,#ddad2d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efd48c', endColorstr='#ddad2d',GradientType=0 );
  color: #fff;
}

.iti,
.iti.iti--allow-dropdown{
  width: 100%;
}

.side-note button{
  min-width: 100px;
}

.side-note .btn-sm.btn-process {
  line-height: 32px;
  padding: 0 5px;
}

.settlement-side-note .btn-sm.btn-process{
  line-height: 32px;
  padding: 0 5px;
  width: calc(100% / 2 - 5px);
}

.change-password label{
  line-height: 34px;
}

.change-password label>span+span{
  color: #FF0000;
}

.btn-red{
  background: #cc001b ;
  border-radius: 0;
}

.btn-red:hover{
  background: #ffc527;
  color: #fff;
}

.ngdialog.ngdialog-theme-default.ngdialog-wallet .ngdialog-close:before{
  top: 2rem;
  right: 2rem;
}

.ngdialog-settlement-page .ngdialog-main-default__content{
  padding: 20px;
  border: 1px solid #ffbf00;
}

.ngdialog.ngdialog-theme-default.ngdialog-settlement .ngdialog-close:before{
  /*top: 15px;*/
  right: 10px;
}

.ngdialog-wallet .ngdialog-title{
  height: 72px;
  background: url(/common/images/logo.png) center #1d1d1d no-repeat;
  border-radius: 10px 10px 0 0;
  border: 1px solid #1d1d1d;
}

.ngdialog-wallet .ngdialog-main-nav{
  background: #080808;
  border: 1px solid #eaab33;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-bottom: 0;
}

.ngdialog-wallet-page select{
  background: url("/common/images/chevron-down.png") #080808  center right 15px no-repeat !important;
  border: 1px solid #72757e;
}

.ngdialog-wallet-page select optgroup,
.ngdialog-wallet-page select option{
  background: #000000;
}

.ngdialog-wallet-page fieldset label span{
  color: #ffffff;
}

.ngdialog-wallet-page fieldset label span + span{
  color: #cc001b;
}

.ngdialog-wallet__header {
  color: #999999;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #392812;
  background: -moz-linear-gradient(top, #392812 0%, #1a0e01 100%);
  background: -webkit-linear-gradient(top, #392812 0%,#1a0e01 100%);
  background: linear-gradient(to bottom, #392812 0%,#1a0e01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#392812', endColorstr='#1a0e01',GradientType=0 );
}

.balance__content.total{
  border-bottom: 0;
  background: #424242;
  color: #fff;
  height: 40px;
  margin: 5px 15px 0 0;
}

.deposit fieldset:last-of-type, .withdraw fieldset:last-of-type {
  border-bottom: 0;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle){
  margin-right: 10px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.btn-group>.btn:last-child:not(:first-child), .btn-group>.dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.width10{
  width: 100px;
}

.width15{
  width: 150px;
}

.width20{
  width: 200px;
}

.width25{
  width: 250px;
}

.width05{
  width: 50px;
}

.ngdialog-wallet-page .btn-violet{
  line-height: 30px;
  width: 436px;
  margin: 0 auto;
  font-size: 14px;
}

.side-note .btn-sm.btn-violet{
  line-height: 32px;
  padding: 0 5px;
  width: 100px;
  height: 40px;
  font-size: 14px;
}


.btn-violet.btn-point {
  width: calc(100% / 2);
}

.settlement-side-note .btn-sm.btn-violet{
  padding: 0 5px;
  width: calc(100% / 2 - 5px);
  font-size: 14px;
  height: 40px;
}


.ngdialog-wallet-page .btn-violet {
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  height: 45px;
  color: #ffffff;
  transition: all .5s;
}

.ngdialog-wallet-page .btn-violet.btn-point{
  font-weight: 700;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
}

.ngdialog-wallet-page .btn-violet.btn-point:hover,
.ngdialog-wallet-page .btn-violet.btn-point.active{
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #FFFFFF;
}

.ngdialog-wallet-page .btn-violet:hover{
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #FFFFFF;
}

.btn.btn-violet.btn-point{
  outline: 0;
  outline-offset: 0;
}

.history-table.table tr:nth-child(even)>td{
  background: #161616;
}

.ngdialog-wallet-page .settlement-side-note .btn-violet {
  font-weight: 700;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
  border-radius: 4px;
}

.ngdialog-wallet-page .settlement-side-note .btn-violet:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#a44719',GradientType=0 );
  color: #ffffff;
}

.ngdialog-wallet-page .change-password fieldset{
  border-bottom: 1px solid #424242;
  padding-right: 20px;
}

.ngdialog-wallet-page .change-password fieldset:last-child{
  border: none;
}

.ngdialog-wallet-page .btn-glyphicon {
  /*background: url(/common/images/refresh-icon.svg) #ff7f17 center no-repeat;*/
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  width: 35px;
  height: 35px;
  position: relative;
  top: 8px;
  border-radius: 6px;
  transition: all .5s;
  padding: 8px 0;
}

.ngdialog-wallet-page .btn-glyphicon:hover {
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #FFFFFF;
}

.ngdialog-wallet-page .balance-header__content {
  padding-left: 10px;
}

.ngdialog-wallet-page .balance-wallet {
  margin-right: 5px;
}

.ngdialog-wallet-page .transfer .btn-violet {
  width: 100%;
  height: 45px;
}

.balance-header__content h4,
.transfer h4{
  margin: 20px 0 0;
}

.deposit .col-xs-5,
.withdraw .col-xs-5 {
  padding-right: 20px;
  line-height: 1.4;
}

.button-container.point,
.bonus-history h4 {
  margin-bottom: 20px;
  font-weight: bold;
}

.button-container.point {
  display: flex;
  justify-content: space-between;
}

.button-container.point button:first-child{
  margin-right: 10px;
}

.change-password .signup-error{
  padding: 10px;
  background: #181818;
  color: #d54b4b;
}
