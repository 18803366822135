.slots-page,
.slots{
  font-size: 0;
}
.slots-page .slot-nav,
.slots .slot-nav{
  background: #0d0c0c;
  padding-bottom: 10px;
  text-align: left;
}

.slots .slot-nav-links{
  padding: 0 20px;
}
.slots-page .slot-nav-links>li,
.slots .slot-nav-links>li{
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-size: 14px;
  letter-spacing: -0.4px;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  background: #505050;
  border: 1px solid #505050;
  margin-right: 10px;
  margin-bottom: 10px;
  /*transition: all .5s;*/
  border-radius: 4px;
}
.slots-page .slot-nav-links>li>a,
.slots .slot-nav-links>li>a{
  color: #666666;
}

.slots-page .slot-nav-links>li.activeNav,
.slots-page .slot-nav-links>li:hover{
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #FFFFFF;
}

/*.slots-page .slot-nav-links>li:hover span,
.slots-page .slot-nav-links>li.activeNav span,
.slots .slot-nav-links>li:hover span,
.slots .slot-nav-links>li.activeNav span{
  border-bottom: 2px solid #c1ad8b;
  padding-bottom: 13px;
  color: #c1ad8b;
}*/

.slots-page .slot-nav-links>li.activeNav a,
.slots .slot-nav-links>li.activeNav a{
  color: #c1ad8b;
}
.slots .list-inline.no-margin>li:last-of-type{
  padding: 0;
}
.slots .list-inline.no-margin>li:last-of-type:hover{
  -moz-box-shadow: unset;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.list-inline.no-margin input{
  border-top: none;
  border-left: 1px solid #282828;
  border-bottom: none;
  border-right: none;
  padding-left: 37px;
  width: 245px;
  background: url("/common/images/search_icon.svg") left 10px center no-repeat;
  color: #666666;
  line-height: 24px;
}

.list-inline.no-margin input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c5c5c5;
  opacity: 1; /* Firefox */
}

.slots-page .slot-nav-links>li>a,
.slots .slot-nav-links>li>a{
  text-decoration: none;
}

.slots-page .slot-con.main-container{
  border: 1px solid #f0f0f0;
  padding: 15px 14px;
  font-size: 0;
  background: #ffffff;
}

.slots-page .slot-button{
  width: calc(1200px / 4);
  margin-right: 10px;
  display: inline-block;
  height: 150px;
  position: relative;
  margin-bottom: 10px;
  padding: 75px 10px 10px;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
}

.slots-page .slot-button:nth-of-type(12){
  padding: 45px;
}

.slots-page .slot-button:hover{
  cursor: pointer;
  box-shadow: inset 0 0 0 4px #49b19c, 0 0 1px rgba(0, 0, 0, 0);
}

.slots-page .slot-button:nth-of-type(4n+4){
  margin-right: 0;
}

.slots-page .slot-button:nth-of-type(n+9){
  margin-bottom: 0;
}

.slots-page .slot-button .html5-icon{
  width: 35px;
  height: 40px;
  position: absolute;
  right: 8px;
  top: -4px;
  background: url("/common/images/sports/icon-html5.png") no-repeat;
}

.slots-page .slot-button-1{
  background: url("/common/images/slots/slot-button/slot-button-1.jpg") no-repeat #000000;
}
.slots-page .slot-button-2{
  background: url("/common/images/slots/slot-button/slot-button-2.jpg") no-repeat #000000;
}
.slots-page .slot-button-3{
  background: url("/common/images/slots/slot-button/slot-button-3.jpg") no-repeat #000000;
}
.slots-page .slot-button-4{
  background: url("/common/images/slots/slot-button/slot-button-4.jpg") no-repeat #000000;
}
.slots-page .slot-button-5{
  background: url("/common/images/slots/slot-button/slot-button-5.jpg") no-repeat #000000;
}
.slots-page .slot-button-6{
  background: url("/common/images/slots/slot-button/slot-button-6.jpg") no-repeat #000000;
}
.slots-page .slot-button-7{
  background: url("/common/images/slots/slot-button/slot-button-7.jpg") no-repeat #000000;
}
.slots-page .slot-button-8{
  background: url("/common/images/slots/slot-button/slot-button-8.jpg") no-repeat #000000;
}
.slots-page .slot-button-9{
  background: url("/common/images/slots/slot-button/slot-button-9.jpg") no-repeat #000000;
}
.slots-page .slot-button-10{
  background: url("/common/images/slots/slot-button/slot-button-10.jpg") no-repeat #000000;
}
.slots-page .slot-button-11{
  background: url("/common/images/slots/slot-button/slot-button-11.jpg") no-repeat #000000;
}
.slots-page .slot-button-12{
  background: url("/common/images/slots/slot-button/slot-button-12.jpg") no-repeat #000000;
}

.slots-page .slot-con.main-container .btn-red {
  height: 30px;
  width: 90px;
  line-height: 0;
}

.slots-page .slot-button .sub-title{
  font-size: 14px;
  color: #999999;
}

.slots-page .main-container.slot-con .slot-button:last-of-type{
  text-align: center;
  vertical-align: top;
}

.empty-search-container{
  margin: auto;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.slot-noresult__img {
  text-align: center;
  margin-bottom: 10px;
}

.slot-noresult__title {
  font-size: 30px;
  color: #6a6a6a;
  font-weight: 700;
}

.slot-noresult__subtitle {
  color: #6a6a6a;
  font-size: 16px;
}


/*view slots*/
.slots-page .banner-content{
  background: url("/common/images/slot-banner.jpg") center center no-repeat #000000;
  height: 306px;
  max-width: 2560px;
  margin: 0 auto;
  padding-top: 85px;

}

.slot-wrapper{
  padding: 0;
}
.wrapper__content{
  margin: 0 auto;
}
.slots .sub-slot-button{
  border: 1px solid #282828;
  background-color: #111111;
}

.slots .sub-slot-button li{
  text-transform: uppercase;
  color: #999999;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  padding-right: 10px;
}

.slots .sub-slot-button li.active span,
.slots .sub-slot-button li:hover span{
  border-bottom: 2px solid #c1ad8b;
  padding-bottom: 12px;
  color: #c1ad8b;
}

.slots .bbin-page{margin-bottom: 10px; cursor: pointer;}

.slots .video-container{
  position: relative;
}
.slots .video-frame{
  position: absolute;
  width: 100%;
  top:0
}
.slots .video-panel{
  position: absolute;
  width: 100%;
  margin: 56px 0 0 10px;
}

.slots .video-panel.video-panel-1005{
  margin: 56px 0 0 275px;
}

.slots .place-holder-layout-title{
  width: 100%;
  height: 28px;
  background: #d5d5d5;
  position: absolute;
  bottom: 0;
}

/*SLOT GAMES*/
.slot-box{
  float: left;
  margin: 0;
  position: relative;
  font-size: 13px;
  overflow: hidden;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -0.4px;
  width: 175px;
  height: 218px;
  background: transparent;
  border: 1px solid #424242;
}

.slot-box-1138 {
  height: 205px;
}

.slot-box--hover {
  border: 1px solid #ff7f17;
  height: 100%;
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 9;
}

.slot-box--hover .btn {
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #ffffff;
}

.slot-box--hover .btn:hover {
  border: solid 1px #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%,#a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%,#a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f17', endColorstr='#b58a2c',GradientType=0 );
  color: #FFFFFF;
}

.slot-box:hover .slot-box--hover {
  cursor: pointer;
  opacity: 10;
  transition: opacity 0.2s ease-in-out;
}

/*.slot-box__item.slot-1053{ background-position: 0 -26px !important;}
.slot-box__item.slot-1061{ height: 160px; }
.slot-box__item.slot-1063,
.slot-box__item.slot-1062{ height: 145px; }
.slot-box__item.slot-1034{ height: 160px; }*/
/*.slot-box__item.slot-1005{ background-size: 200% !important; }*/

.slot-box__item.slot-1015{
  height: 142px;
}

.slot-box__item{
  height: 240px;
}

.slot-box__item.slot-1052{
  height: 215px;
}
.slot-box.slot-box-1010 {
  height: 180px;
}
.slot-box.slot-box-1080 {
  height: 215px;
}

.slot-box.slot-box-1112.slot-box-1010 {
  height: 218px;
}

.slot-box__name{
  background-color: #19191d;
  bottom: 0;
  color: #ffffff;
  margin: 0;
  overflow: hidden;
  padding: 10px;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
}

/*SLOT GAMES*/

.slots .banner-content{
  height:245px;
}
.slots .banner-content .promotion-slider{
  margin-top: 70px;
}

.slot-box:last-child {
  margin-right: 0;
}

.slots .main-container.clearfix{
  border: 2px solid #e2e2e2;
  margin-top: 7px;
}

.slots .page-banner>img{
  height: 150px;
  width: 100%;
}

.slots .slot-box:hover .slot-box__item {
  transform: scale(1.4);
  transform-origin: 50% 50%;
  transition: .3s ease-in-out;
  -webkit-transition: .3s ease-in-out;
}

.slots .slot-box--hover p{
  margin-top: 50%;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slots .slot-button-img{
  /*background: url("/common/images/main/slot-banner/slot-hover.png") center no-repeat;*/
  height: 32px;
  width: 113px;
  margin: 0 auto;
  padding: 5px;
  font-weight: bold;
  color: #ffffff;
}

/*odomoeter*/
.slots-page .odometer-container{
  position: absolute;
  bottom: 80px;
  left: 80px;
  overflow: hidden;
}

.slots-page .jackpot-odometer{
  height: 35px;
  width: 297px;
  position: absolute;
  top: 30px;
  right: 30px;
  overflow: hidden;
}

.slots-page .page-banner .banner-text{
  font-size: 30px;
  width: 480px;
  height: 200px;
  position: absolute;
  top: 155px;
  font-weight: bold;
  letter-spacing: -2px;
  text-transform: uppercase;
  color: #8d0000;
}

.slots-page .page-banner .banner-text p{
  margin: 0;
}

.slots-page .page-banner .banner-text p:nth-of-type(2){
  font-size: 20px;
  color: #333333;
}

.slots .slots-button{
  padding: 10px;
}

.sub-slot-button .list-inline.no-margin{
  padding: 0;
}

.slot-wrapper__content{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: auto;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
}

.slot-box.slot-box-1065.slot-box-cs5moneyroll,
.slot-box.slot-box-1065.slot-box-cs3irishcharms,
.slot-box.slot-box-1065.slot-box-cs3w,
.slot-box.slot-box-1065.slot-box-cs5triple8gold,
.slot-box.slot-box-1065.slot-box-vs1dragon8,
.slot-box.slot-box-1065.slot-box-vs10egypt,
.slot-box.slot-box-1065.slot-box-vs4096bufking,
.slot-box.slot-box-1065.slot-box-vs20asgard,
.slot-box.slot-box-1065.slot-box-vs7fire88,
.slot-box.slot-box-1065.slot-box-vs1fortunetree,
.slot-box.slot-box-1065.slot-box-vs1tigers,
.slot-box.slot-box-1065.slot-box-vs1fufufu,
.slot-box.slot-box-1065.slot-box-vs1money,
.slot-box.slot-box-1065.slot-box-vs20bonzgold,
.slot-box.slot-box-1065.slot-box-vs20pbonanza,
.slot-box.slot-box-1065.slot-box-vs20fruitsw,
.slot-box.slot-box-1065.slot-box-vs20sbxmas,
.slot-box.slot-box-1065.slot-box-vs5littlegem,
.slot-box.slot-box-1065.slot-box-vs25goldrush,
.slot-box.slot-box-1065.slot-box-vswayscryscav,
.slot-box.slot-box-1111.slot-box-236,
.slot-box.slot-box-1111.slot-box-270,
.slot-box.slot-box-1111.slot-box-148,
.slot-box.slot-box-1111.slot-box-175,
.slot-box.slot-box-1112.slot-box-1891,
.slot-box.slot-box-1112.slot-box-7937,
.slot-box.slot-box-1112.slot-box-1063,
.slot-box.slot-box-1156.slot-box-160122,
.slot-box.slot-box-1156.slot-box-160199,
.slot-box.slot-box-1156.slot-box-160305,
.slot-box.slot-box-1156.slot-box-160165,
.slot-box.slot-box-1156.slot-box-160281,
.slot-box.slot-box-1008.slot-box-tgow2plus,
.slot-box.slot-box-1008.slot-box-bird,
.slot-box.slot-box-1066.slot-box-SGFourDivineBeasts,
.slot-box.slot-box-1065.slot-box-vs243goldfor,
.slot-box.slot-box-1112.slot-box-1290,
.slot-box.slot-box-1112.slot-box-6069,
.slot-box.slot-box-1112.slot-box-1037,
.slot-box.slot-box-1112.slot-box-1035,
.slot-box.slot-box-1112.slot-box-1110,
.slot-box.slot-box-1112.slot-box-1207,
.slot-box.slot-box-1112.slot-box-1293,
.slot-box.slot-box-1112.slot-box-2070,
.slot-box.slot-box-1066.slot-box-SGDiscoBeats,
.slot-box.slot-box-1156.slot-box-160183,
.slot-box.slot-box-1065.slot-box-vs20olympgrace,
.slot-box.slot-box-1156.slot-box-160090,
.slot-box.slot-box-1156.slot-box-160134,
.slot-box.slot-box-1156.slot-box-160214
{
  display: none;
}
