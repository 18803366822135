.ngdialog.ngdialog-theme-default.ngdialog-gamespopup {
  padding-top: 0;
}

.ngdialog.ngdialog-theme-default.ngdialog-gamespopup .ngdialog-overlay{
  background: rgba(0, 0, 0, 0.7);
}

.ngdialog.ngdialog-theme-default.ngdialog-gamespopup .ngdialog-close{
  text-shadow: none;
}

.ngdialog.ngdialog-theme-default.ngdialog-gamespopup .ngdialog-close:before {
  font-size: 45px;
  right: 30px;
  top: 30px;
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  /* padding: 10px; */
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.ngdialog.ngdialog-theme-default.ngdialog-gamespopup .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-default.ngdialog-gamespopup .ngdialog-close:active:before {
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #FFFFFF;
}

.ngdialog.ngdialog-theme-default.ngdialog-gamespopup .ngdialog-content{
  min-width: 1230px;
  /*min-height: 550px;*/
  border-radius: 0;
  background: #0c0c0e;
  border: 1px solid #1c1c1c;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  /*text-shadow: 0px 0px 5px #757879, 0px 0px 15px #757879, 0px 0px 20px #757879;*/
  text-align: center;
  animation: slideInDown .5s;
  -webkit-animation: slideInDown .5s;
  padding: 30px;
}

.ngdialog-games-page nav{
  margin-bottom: 30px;
}
.ngdialog-games-page nav .list-inline{
  width: 1115px;
  text-align: left;
}

.ngdialog-games-page nav .list-inline>li{
  border-radius: 4px;
  border: solid 1px #ff7f17;
}
.ngdialog-games-page nav .list-inline>li{
  width: 213px;
  height: 50px;
  text-transform: uppercase;
  line-height: 50px;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
}

.ngdialog-games-page nav .list-inline>li:hover,
.ngdialog-games-page nav .list-inline>li.active{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
  text-shadow: none;
}


.ngdialog-games-page nav .list-inline>li>a{
  text-decoration: none;
  color: #ffffff;
}

.ngdialog-games-page .game-button-container{
  width: 1175px;
  /*height: 404px;*/
  position: relative;
  font-size: 0;
  margin: 0 auto;
  display: block;
  animation: cc_anim 1s ease 1;
}

.ngdialog-games-page .game-buttons-popup{
  width: 186px;
  height: 200px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  outline: solid thin #34363d;
}

.ngdialog-games-page .game-buttons-popup:hover{
  outline: 1px solid #ff7f17 !important;
}


.ngdialog-games-page .game-buttons-popup.sports{
  width: 225px;
  height: 385px;
  float: left;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  outline: solid 1px #34363d;
  /* width: calc(95% / 6); */
  /* height: 202px; */
  /* margin-bottom: 10px; */
}

.ngdialog-games-page .game-buttons-popup.mini{
  width: 380px;
  height: 384px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  outline: solid 1px #34363d;
}

.ngdialog-games-page .game-buttons-popup.Others {
  width: auto;
  height: 385px;
  outline: solid 1px #34363d;
  margin: 0;
}

.ngdialog-games-page .game-buttons-popup.live:nth-of-type(6n + 6),
.ngdialog-games-page .game-buttons-popup.slot:nth-of-type(6n + 6),
.ngdialog-games-page .game-buttons-popup.sports:nth-of-type(6),
.ngdialog-games-page .game-buttons-popup.mini:nth-of-type(3){
  margin-right: 0;
}

/*.ngdialog-games-page .game-buttons-popup.live:nth-of-type(n + 7),*/
.ngdialog-games-page .game-buttons-popup.slot:nth-of-type(n + 19){
  /*margin-bottom: 0;*/

}

/*.ngdialog-games-page .game-buttons-popup.live:hover::after,*/
.ngdialog-games-page .game-buttons-popup.slot:hover::after,
.ngdialog-games-page .game-buttons-popup.mini:hover::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  /*border: 3px solid #fff;*/
  /*box-shadow: 0px 0px 10px #d153c7, 0px 0px 10px #d153c7;*/
  transition: 0.5s;
}

@keyframes cc_anim {
  0% {
    top: -40px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.ngdialog-games-page .game-buttons-popup:hover .overlay{
  opacity: 1;
}

.ngdialog-games-page .game-buttons-popup .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
  opacity: 0;
  background: url("/common/images/game-buttons/company.png") left top no-repeat rgba(16, 16, 16, 0.72);
  z-index: 1;
}

.ngdialog-games-page .game-buttons-popup .overlay .tbl {
  display: table;
  width: 100%;
  height: 100%;
}

.ngdialog-games-page .game-buttons-popup .overlay .center {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: auto;
  height: auto;
}

.ngdialog-games-page .game-buttons-popup .overlay button {
  width: 80px;
  height: 80px;
  background-color: transparent;
  border: none;
  color: #fff;
  position: relative;
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}

.ngdialog-games-page .game-buttons-popup .overlay.sports button {
  width: 120px;
  height: 40px;
}

.ngdialog-games-page .game-buttons-popup .overlay button img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  animation: play_anim 2s linear infinite;
}

.ngdialog-games-page .game-buttons-popup .overlay .title {
  min-width: 130px;
  height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #FFFFFF;
  margin: auto;
  display: table;
}

.ngdialog-games-page .game-buttons-popup .overlay .title:hover {
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
}

.ngdialog-games-page .game-buttons-popup .overlay .title p {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-size: 14px;
  /*font-family: NotoSansKr-Regular;*/
  /*text-shadow: 0px 0px 20px #eb8aec;*/
}

@keyframes play_anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*search*/
.search-container{
  position: absolute;
  right: 10px;
}
.search-container label{
  color: #ffffff;
  margin: 0;
}
.search-container .form-control{
  display: inline-block;
  width: 70%;
  color: #555;
  background: #080808;
  border: 1px solid #72757e;
}

/*load slot*/
.slots-games-container{
  background-color: #f7f7f7;
  transition: 0.5s;
  position: relative;
  display: none;
  width: 1165px;
  margin: 0 auto;
  animation: cc_anim 1s ease 1;
  border-radius: 10px;
}

.slots-games-container .header{
  width: 100%;
  height: 78px;
  float: left;
  display: table;
  background: #000000 no-repeat;
  background-size: cover !important;
}
.slots-games-container .header .slot-header {
  position: relative;
  display: inline-block;
  float: left;
  margin-left: 10px;
}
.slots-games-container .header p {
  color: #fff;
  font-size: 18px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  /*font-family: NotoSansKr-Regular;*/
  font-weight: 700;
  margin: 0;
  padding: 10px;
}
.slots-games-container .header img{
  position: relative;
  display: inline-block;
  padding: 10px;
}

.slots-games-container .header button {
  width: 40px;
  height: 40px;
  border: none;
  background: url("/common/images/slots/sg_close.png") center center no-repeat transparent;
  float: right;
  margin-top: 18px;
  margin-right: 21px;
  background-size: 100% 100%;
  position: relative;
  z-index: 2;
  transition: 0.5s;
}

.slots-games-container .header button:hover,
.slots-games-container .header button:focus{
  background-image: url("/common/images/slots/sg_close_on.png");
  outline: none;
  border: none;
}

.slots-page .sub-slot-button{
  width: 100%;
  height: 50px;
  background-color: #202020;
  float: left;
  position: relative;
  line-height: 49px;
  text-align: left;
  font-size: 14px;
}

.slots-page .sub-slot-button .list-inline{
  margin-left: 0;
}

.slots-page .sub-slot-button .list-inline>li{
  font-size: 13px;
  padding: 0 15px;
  transition: 0.5s;
  cursor: pointer;
  color: #ffffff;
}

.slots-page .sub-slot-button .list-inline>li img.checkActive{
  opacity: 0;
}

.slots-page .sub-slot-button .list-inline>li.active,
.slots-page .sub-slot-button .list-inline>li:hover{
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #ffffff;text-shadow: none;
}

.slots-page .sub-slot-button .list-inline>li.active img.checkActive{
  opacity: 1;
}

.btn.btn-sm.play{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 35px;
  color: #ffffff;
  font-size: 14px;
  /*font-family: NotoSansKr-Regular;*/
  transition: 0.5s;
}

/*view slots*/
.slots-games-container .slots-button{
  background: #0c0c0e;
}
.slot-wrapper{
  padding: 0 16px 16px 23px;
  height: 673px;
  width: 100%;
  margin-top: 16px;
  overflow-x: hidden;
  background-color: #0c0c0e;
}
.wrapper__content{
  margin: 0 auto;
}
.slots .sub-slot-button{
  border-bottom: 1px solid #424242;
  background-color: #ffffff;
}

.slots .sub-slot-button li{
  text-transform: uppercase;
  color: #666666;
  line-height: 40px;
  text-align: center;
  padding: 1px 18px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.slots .sub-slot-button li.active span,
.slots .sub-slot-button li:hover span{
  border-bottom: 2px solid #a50000;
  padding-bottom: 9px;
  color: #a50000;
}

.slots .bbin-page{margin-bottom: 10px; cursor: pointer;}

.slots .video-container{
  position: relative;
}
.slots .video-frame{
  position: absolute;
  width: 100%;
  top:0
}
.slots .video-panel{
  position: absolute;
  width: 100%;
  margin: 56px 0 0 10px;
}

.slots .video-panel.video-panel-1005{
  margin: 56px 0 0 275px;
}

.slots .place-holder-layout-title{
  width: 100%;
  height: 28px;
  background: #d5d5d5;
  position: absolute;
  bottom: 0;
}

/*SLOT GAMES*/
.slot-box{
  float: left;
  margin: 0 10px 10px 0;
  position: relative;
  font-size: 13px;
  overflow: hidden;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -0.4px;
  width: 147px;
  height:179px;
  background: #f2f2f2;
  outline: solid thin #000000;
  transition: 0.5s;
}


.slot-box--hover {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 9;
}

.slot-box:hover .slot-box--hover {
  cursor: pointer;
  opacity: 10;
  transition: opacity 0.2s ease-in-out;
}


.slot-box__item{
  /*height: 145px;*/
  width: 173px;
}

.slot-box.slot-box-1052,
.slot-box.slot-box-1102 {
  /*height: 259px;*/
}
.slot-box.slot-box-1071 {
  height: 167px;
}

.slot-content-1102 .slot-box--hover .btn {
  margin-top: 35%;
}

.slot-box__name{
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  color: #ffffff;
  margin: 0;
  overflow: hidden;
  padding: 5px;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

/*SLOT GAMES*/
.slot-box:nth-of-type(6n+6) {
  margin-right: 0;
}

.slots .main-container.clearfix{
  border: 2px solid #e2e2e2;
  margin-top: 7px;
}

.slots .page-banner>img{
  height: 150px;
}

.slots-page .slot-box:hover .slot-box__item {
  transform: scale(1.4);
  transform-origin: 50% 50%;
  transition: .3s ease-in-out;
  -webkit-transition: .3s ease-in-out;
  z-index: 2;
  position: relative;
}

.ngdialog-games-page .game-buttons-popup:hover .game-buttons-name,
.slots-page .slot-box:hover p{
  color: #ffffff;
  background: #0d0c0c;
}

.slots-page .slot-box--hover p{
  margin-top: 50%;
  color: #ffffff;
  white-space: nowrap;
}

/*
 *  STYLE 4
 */

.scrollbar{
  overflow-y: scroll;
}

.ngdialog-games-page .game-buttons-popup.live:hover::after,
.ngdialog-games-page .game-buttons-popup.slot:hover::after,
.ngdialog-games-page .game-buttons-popup.sports:hover::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s;
}



#slots-con::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);
  background-color: #000000;
}

#slots-con::-webkit-scrollbar
{
  width: 14px;
  background-color: #260525;
}

#slots-con::-webkit-scrollbar-thumb
{
  background-color: #ff7f17;
  border: 2px solid #ff7f17;
}

.loadingGifSlot{
  position: absolute;
  bottom: 50px;
}

.slot-open .fa-times-circle-o{
  font-size: 40px!important;
  color: #999999!important;
}
.slot-open .fa-times-circle-o{
  position: absolute;
  transform: translateY(-50%);
  top:50%;
  right: 0;
}
.slot-open .fa-times-circle-o:hover,
.slot-open .fa-times-circle-o:focus,
.slot-open .fa-times-circle-o:active{
  color: #ffffff!important;
}

.slot-box--hover .btn.btn-sm.play:hover{
  /*background: #ead594;*/
}
.main-btn-sports,
.main-btn-sports.active:hover{
  width: 100%;
  height: 40px;
  margin: 0 2px;
  margin-top: 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #fe301d;
  background: #fe301d;
  background: -moz-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: -webkit-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: linear-gradient(to bottom, #ea2c1a 0%, #7b1212 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  color: #fff;
  font-size: 13px;
  /*font-family: NotoSansKr-Bold;*/
  text-shadow: 0px 0px 18px #8a8f90, 0px 0px 18px #8a8f90;
  word-break: keep-all;
  font-weight: bold;
}

/*.game-buttons-popup.sports:nth-of-type(5) .main-btn-sports,
.game-buttons-popup.sports:nth-of-type(5) .main-btn-sports.active:hover{
  height: 90px;
}*/

.main-btn-sports.active,
.main-btn-sports:hover {
  border-radius: 4px;
  border: 1px solid #fe301d;
  background: #fe301d;
  background: -moz-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: -webkit-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: linear-gradient(to bottom, #ea2c1a 0%, #7b1212 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
}

.slot-box.slot-box-1065.slot-box-vs1024butterfly,
.slot-box.slot-box-1066.slot-box-SGSojuBomb,
.slot-box.slot-box-1156.slot-box-160194,
.slot-box.slot-box-1080.slot-box-C-BK01
{
  display: none;
}
