/*banner*/

.banner .banner-slider img.banner-outside {
  width: 100%;
  object-fit: cover;
  height: 600px;
  margin-top: 127px;
}

.banner .banner-slider .slick-slide:focus{
  outline: none;
  border: none;
}

.banner .banner-slider .btn-learn{
  position: absolute;
  width: 200px;
  height: 40px;
  right: 210px;
  top: -210px;
  border-radius: 20px;
  border: 2px solid #c1ad8b;
  background: transparent;
  color: #c1ad8b;
  padding: 0;
  outline: none !important;
  font-weight: bold;
}

.banner .banner-slider .btn-learn:hover {
  background: #c1ad8b;
  color: #111111;
}
/*banner end*/
.banner .banner-slider .slick-prev{
  left: 20px;
  z-index: 1;
}

.banner .banner-slider .slick-prev::before,
.banner .banner-slider .slick-next::before{
  font-size: 30px;
  opacity: .5;
}

.banner .banner-slider .slick-prev,
.banner .banner-slider .slick-next{
  width: 30px;
  height: 30px;
}

.banner .banner-slider .slick-next{
  right: 20px;
}


.banner-slider .main-promo-text{
  position: absolute;
  top: -395px;
  right: 85px;
}

.banner-slider .slick-dots {
  bottom: -36px;
}

.banner-slider .slick-dots li button:before {
  font-size: 12px;
  opacity: 1;
}

.banner-slider .slick-dots li.slick-active button:before {
  color: #d8278f;
}

.banner-slider .slick-dots li button:before {
  color: #c3c1c5;
}

.promo-text-slider{
  width: 645px;
  position: absolute;
  top: -390px;
  right: 0px;
  text-align: center;
}

.promo-text-btn{
  width: 200px;
  height: 40px;
  background: #0d0c0c;
  border: 2px solid #f5aa18;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 17px;
}

.promo-text-btn:hover,
.promo-text-btn:focus{
  background: #f5aa18;
  outline: none;
  color: #ffffff;
}
