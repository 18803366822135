.main-page .main-container{
  width: 1260px;
  padding: 0 0 0 10px;
}

.main-page .left-container{
  float: left;
  position: relative;
  width: 832px;
  margin-top: 10px;
}

.main-page .left-container .banner {
  width: 748px;
  height: 186px;
  background: url('/common/images/main/bg_off.png') no-repeat;
  position: relative;
}

.main-page .banner .bg{
  position: absolute;
  left: -8px;
  top: -10px;
  animation: banner_anim 1s ease infinite;
  z-index: 0;
}

.main-page .banner img.girl {
  position: absolute;
  z-index: 1;
  left: 57px;
  bottom: 9px;
  animation: b_girl_anim 8s ease infinite;
}

.main-page .banner img.pirate {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 9px;
  animation: b_pirate_anim 8s ease infinite;
}

.main-page .left-container .game-buttons {
  position: relative;
  width: 100%;
  margin-top: 42px;
  font-size: 0;
}

.main-page .left-container .game-category{
  width: calc(100% / 4);
  height: 256px;
  display: inline-block;
  position: relative;
  animation: cat_all_anim 1s ease forwards 1;
  cursor: pointer;
  opacity: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.main-page .left-container .game-category>span{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 9px;
  text-shadow: 0px 0px 18px #e19e29, 0px 0px 18px #e19e29;
  color: #ffffff;
}

.main-page .left-container .game-category:nth-of-type(n+3)>span{
  bottom: 0;
}


.main-page .left-container .game-category img:first-of-type{
  display: block;
  position: relative;
  margin-bottom: 16px;
}

.main-page .left-container .game-category:nth-of-type(-n + 3) img:first-of-type{
  margin-left: -10px;
}

.main-page .left-container .game-category:hover img{
  animation: cat_anim 1s ease-in-out infinite;
}


/*slider*/
.banner .carousel,
.banner .carousel .carousel-inner{
  width: 100%;
  height: 100%;
}
.banner .carousel .carousel-inner .item img.text {
  position: absolute;
  left: 20px;
  right: 0;
  top: 0px;
  margin: auto;
  animation: b_text_anim 4s ease infinite;
}

/*animation*/
@keyframes banner_anim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes b_girl_anim {
  0% {
    opacity: 0;
    left: 0;
  }
  10% {
    opacity: 0;
    left: 0;
  }
  20% {
    opacity: 1;
    left: 57px;
  }
  90% {
    opacity: 1;
    left: 57px;
  }
  100% {
    opacity: 0;
  }
}

@keyframes b_pirate_anim {
  0% {
    opacity: 0;
    right: -99px;
  }
  10% {
    opacity: 0;
    right: -99px;
  }
  20% {
    opacity: 1;
    right: -25px;
  }
  90% {
    opacity: 1;
    right: -25px;
  }
  100% {
    opacity: 0;
  }
}

@keyframes cat_all_anim {
  0% {
    top: -30px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes cat_anim {
  0% {
    top: 0;
  }
  50% {
    top: 10px;
  }
  100% {
    top: 0;
  }
}

@keyframes b_text_anim{
  0% {
    opacity: 0;
    transform: scale(3);
  }
  18% {
    opacity: 0;
    transform: scale(3);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


/*right container*/

.main-page .right-container{
  width: 418px;
  float: right;
  position: relative;
  top: 0;
}

.right-container .first_chain{
  position: absolute;
  margin: 0 auto;
  display: block;
  top: 0;
  right: 95px;
}

.right-container .customer_24{
  width: 398px;
  height: 55px;
  background: url("/common/images/main/right-container/customer-header.png") no-repeat;
  display: block;
  margin: 0 auto;
  position: relative;
  margin-top: 75px;
  box-shadow: 25px 15px 20px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.right-container .customer_24 .deco {
  position: absolute;
  left: -4px;
  top: -11px;
}

.right-container .customer_24 .contact {
  width: 380px;
  height: 109px;
  background: url("/common/images/main/right-container/customer-bg.jpg") no-repeat;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: 0.5s;
  display: table;
}

.right-container .customer_24:hover .contact {
  top: 50px;
  opacity: 1;
}

.right-container .customer_24 .contact .text {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding-top: 13px;
}

.right-container .customer_24 contact .text p {
  color: #fff;
  font-size: 15px;
  /*font-family: NotoSansKr-Bold;*/
  text-shadow: 0px 0px 18px #c981ea;
  padding: 2px 0;
}

/*notice table*/

.right-container .notice-table{
  width: 398px;
  height: 220px;
  position: relative;
  background: url(/common/images/main/right-container/event-bg.jpg) no-repeat;
  margin: 10px auto 0;
  padding: 20px 35px;
}

.right-container .notice-table .head {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  padding-top: 6px;
  padding-bottom: 2px;
}

.right-container .notice-table .head:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #f8d670;
  box-shadow: 0px 0px 9px rgba(163, 141, 21, 0.72);
}

.right-container .notice-table .head:after {
  content: "";
  position: absolute;
  right: 5px;
  bottom: -3px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #cccccc;
}

.right-container .notice-table .head .btn-grp {
  float: left;
  font-weight: bold;
}

.right-container .notice-table .head button.active {
  color: #ffffff;
  font-size: 21px;
  /*font-family: NotoSansKr-Bold;*/
  text-shadow: 0px 0px 15px #fff;
  margin-left: -5px;
}

.right-container .notice-table .head button {
  width: auto;
  height: 40px;
  float: left;
  border: none;
  background-color: transparent;
  color: #ada9a9;
  font-size: 16px;
  /*font-family: NotoSansKr-Bold;*/
  transition: 0.5s;
  padding: 0;
  position: relative;
  margin-left: 25px;
}

.right-container .notice-table .head button.active img {
  opacity: 1;
  position: relative;
  top: -2px;
}

.right-container .notice-table .head button img {
  opacity: 0;
  position: absolute;
  transition: 0.5s;
}

.right-container .notice-table .head a {
  float: right;
  color: #cccccc;
  font-size: 11px;
  font-family: NanumGothic-Regular;
  margin-top: 18px;
  font-weight: bold;
  cursor: pointer;
}

.right-container .notice-table table {
  width: 100%;
  float: left;
  margin-top: 10px;
}

.right-container .notice-table table tr {
  height: 26px;
}

.right-container .notice-table table td:first-child {
  text-align: left;
}

.right-container .notice-table table td {
  color: #ffffff;
  font-size: 12px;
  /*font-family: NotoSansKr-Regular;*/
}

.right-container .notice-table table td:last-child {
  text-align: right;
}

.right-container .notice-table .head button:focus{
  outline: none;
  border: 0;
}

/*deposit/withdraw*/
.right-container .dep-wit {
  width: 398px;
  height: 220px;
  padding: 0 20px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.44);
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
  background: url(/common/images/main/right-container/deposit-withdraw-bg.jpg) 100% no-repeat;
}

.right-container .dep-wit .head {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.right-container .dep-wit .head button:focus{
  outline: unset;
  border: none;
}

.right-container .dep-wit .head button .en {
  font-size: 11px;
  /*font-family: NotoSansKr-Regular;*/
  padding: 0 10px;
}

.right-container .dep-wit .head button .icon {
  font-size: 14px;
  /*font-family: NotoSansKr-Regular;*/
}

.right-container .dep-wit .head button .deco {
  position: absolute;
  left: 0;
  right: 0;
  top: -14px;
  margin: auto;
  margin: 0 2px;
  transition: 0.5s;
  opacity: 0;
}

.right-container .dep-wit .head button.active .deco {
  opacity: 1;
}

.right-container .dep-wit .head button {
  width: 110px;
  height: 40px;
  margin-top: 14px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #21d94f;
  background: #21d94f;
  background: -moz-linear-gradient(top, #21d94f 1%, #ecef47 100%);
  background: -webkit-linear-gradient(top, #21d94f 1%,#ecef47 100%);
  background: linear-gradient(to bottom, #21d94f 1%,#ecef47 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffd2', endColorstr='#1a6ffe',GradientType=0 );
  color: #fff;
  font-size: 13px;
  /*font-family: NotoSansKr-Bold;*/
  /*text-shadow: 0px 0px 18px #8a8f90, 0px 0px 18px #8a8f90;*/
  word-break: keep-all;
  font-weight: bold;
  padding: 0;
}

.right-container .dep-wit .head button:last-child {
  border: 1px solid #4776E6;
  background: #4776E6;
  background: -moz-linear-gradient(top, #4776e6 1%, #8e54e9 100%);
  background: -webkit-linear-gradient(top, #4776e6 1%,#8e54e9 100%);
  background: linear-gradient(to bottom, #4776e6 1%,#8e54e9 100%);
}

.right-container .dep-wit .head button.active,
.right-container .dep-wit .head button:hover {
  border-radius: 4px;
  border: 1px solid #fe301d;
  background: #fe301d;
  background: -moz-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: -webkit-linear-gradient(top, #ea2c1a 0%, #7b1212 100%);
  background: linear-gradient(to bottom, #ea2c1a 0%, #7b1212 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
}

.right-container .dep-wit .carousel {
  width: 100%;
}

.right-container .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.right-container .dep-wit table {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 10px;
}

.right-container .dep-wit .carousel .carousel-inner .item .list {
  width: 100%;
  margin-top: 0px;
  animation: 1;
  animation-name: deposit_list;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.right-container .dep-wit table tr {
  width: 100%;
  height: 28px;
  /*font-family: NotoSansKr-Regular;*/
}

.right-container .dep-wit .border-deco.l_t {
  left: 2px;
  top: 2px;
}

.right-container .dep-wit .border-deco {
  position: absolute;
}

.right-container .dep-wit .border-deco.l_b {
  left: 2px;
  bottom: 2px;
  transform: rotate(-90deg);
}

.right-container .dep-wit .border-deco.r_t {
  right: 2px;
  top: 2px;
  transform: rotate(90deg);
}

.right-container .dep-wit .border-deco.r_b {
  right: 2px;
  bottom: 2px;
  transform: rotate(180deg);
}

.right-container .dep-wit table td.amount {
  color: #eaab33;
  font-size: 16px;
  font-weight: bold;
}

.right-container .dep-wit table td {
  color: #ffffff;
  font-size: 12px;
}

.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(1),
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(6){
  animation-delay: 0.1s;
}
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(2),
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(7){
  animation-delay: 0.2s;
}
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(3),
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(8){
  animation-delay: 0.3s;
}
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(4),
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(9){
  animation-delay: 0.4s;
}
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(5),
.right-container .dep-wit .carousel .carousel-inner .item .list:nth-child(10){
  animation-delay: 0.5s;
}

/*right container animation*/
@keyframes m_cs_anim {
  0% {
    margin-top: -81px;
  }

  100% {
    margin-top: -8px;
  }
}

@keyframes noteve_anim {
  0% {
    margin-top: -5px;
  }
  100% {
    margin-top: 30px;
  }
}

@keyframes deposit_list {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}


/*new layout*/
.new-game-button-container{
  width: 100%;
  height: 315px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  background: url("/common/images/main/bg-gamebutton.png")}

.gamebutton{
  width: 100%;
  height: 142px;
  float: left;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all .5s;
}

.gamebutton:nth-child(2),
.gamebutton:nth-child(5){
  margin-right: 0;
}

.gamebutton:nth-child(n+3){
  width: 264px;
  margin-bottom: 0;
}

.gamebutton.live{
  background: url("/common/images/main/game-buttons/game-button-1.png") #000000 no-repeat;
}

.gamebutton.slot{
  background: url("/common/images/main/game-buttons/game-button-2.png") #000000 no-repeat;
}

.gamebutton.live:hover{
  background: url("/common/images/main/game-buttons/game-button-1-hover.png") #000000 no-repeat;
}

.gamebutton.slot:hover{
  background: url("/common/images/main/game-buttons/game-button-2-hover.png") #000000 no-repeat;
}

.contact_panel{
  height: 120px;
  width: 370px;
  background: url(/common/images/main/right-container/customer-bg.jpg) no-repeat;
  margin: 20px auto auto;
  z-index: 1;
  position: relative;
  text-align: center;
  padding-top: 0;
}

.contact_panel .contact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 98%;
}

.contact_panel .contact p {
  margin: 0;
}

.contact .text{
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.font-yellow{
  color: #ffcd01;
}

.dep-wit .en{
  font-size: 11px;
  font-weight: bold;
}

.dep-wit .deposit,
.dep-wit .withdraw{
  letter-spacing: -0.8px;
}
