.ngdialog.ngdialog-theme-default.ngdialog-login .ngdialog-content {
  width: 400px;
  background: #0b0b0b;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 0;
  padding: 0;
}

.ngdialog-login-page p.text-center{
  margin: 20px 0;
}

.ngdialog-login-page .form-control{
  margin-bottom: 10px;
  background: #080808;
  border: 1px solid #72757e;
  /* border-radius: 0; */
  padding-left: 45px;
  color: #ffffff;
  height: 40px;
}

.ngdialog-login-page .btn-red{
  border-radius: 0;
  font-weight: 600;
}

.ngdialog-login-page p.text-center.text-white{
  color: #999999;
  cursor: pointer;
}

.ngdialog-login-page p.text-center.text-white:hover{
  color: #ff7f17;
}

.ngdialog.ngdialog-theme-default.ngdialog-login .ngdialog-close:before {
  font-size: 35px;
  line-height: 26px;
  right: 10px;
  top: 20px;
  width: 30px;
  color: #fff;
}

.ngdialog.ngdialog-theme-default.ngdialog-login .ngdialog-close:hover:before{
  color: #ff7f17;
}

.ngdialog-login-page .btn-yellow{
  height: 45px;
}

.ngdialog-login-page .ngdialog-notice__title {
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #392812;
  background: -moz-linear-gradient(top, #392812 0%, #1a0e01 100%);
  background: -webkit-linear-gradient(top, #392812 0%,#1a0e01 100%);
  background: linear-gradient(to bottom, #392812 0%,#1a0e01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#392812', endColorstr='#1a0e01',GradientType=0 );
}

.ngdialog-login__content{
  height: 275px;
  padding: 0 40px;
  border: 1px solid #eaab33;
  color: #ffffff;
  font-size: 14px;
}

.ngdialog-login-page .form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ngdialog-login-page .btn-yellow{
  margin-top: 20px;
  border: 1px solid #ff7f17;
  background: #0b0a0a;
  background: -moz-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: -webkit-linear-gradient(top, #171717 0%, #0b0a0a 100%);
  background: linear-gradient(to bottom, #171717 0%, #0b0a0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea2c1a', endColorstr='#7b1212', GradientType=0);
  transition: all .5s;
}

.ngdialog-login-page .btn-yellow:hover{
  border: 1px solid #ff7f17;
  background: #ff7f17;
  background: -moz-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: -webkit-linear-gradient(top, #ff7f17 0%, #a44719 100%);
  background: linear-gradient(to bottom, #ff7f17 0%, #a44719 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7f17', endColorstr='#a44719', GradientType=0);
  color: #FFFFFF;
}

.ngdialog-login-page .icon {
  padding: 13px;
  min-width: 45px;
  position: absolute;
  color: #eaab33;
}

.ngdialog-login-page .icon::after{
  content: '';
  position: absolute;
  width: 2px;
  height: 15px;
  background: #72757e;
  right: 6px;
}
